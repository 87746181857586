import React from "react";
import {BankList} from "../../../resources/constants";

export default function ManageReferralForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Referral Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Referral Name</label>
                                <input
                                    name="ReferralName"
                                    className="form-control"
                                    id="ReferralName"
                                    value={props.formData.ReferralName}
                                    onChange={props.onEdit}
                                    placeholder="Referral Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    name="PhoneNumber"
                                    className="form-control"
                                    id="PhoneNumber"
                                    value={props.formData.PhoneNumber}
                                    onChange={props.onEdit}
                                    max={11}
                                    placeholder="Phone Number"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Referral Type</label>
                                <select className="form-control" name="ReferralType" id="ReferralType" value={props.formData.ReferralType} onChange={props.onEdit}>
                                    <option value="">Select Referral Type</option>
                                    <option value="Staff">Staff</option>
                                    <option value="Partners">Partners</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Account Number</label>
                                <input
                                    type="number"
                                    name="AccountNumber"
                                    className="form-control"
                                    id="AccountNumber"
                                    value={props.formData.AccountNumber}
                                    onChange={props.onEdit}
                                    max={11}
                                    placeholder="Account Number"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Bank Name</label>
                                <select className="form-control" name="BankName" id="BankName" value={props.formData.BankName} onChange={props.onEdit}>
                                    <option value="">Select Bank</option>
                                    {
                                        BankList.map((item, index)=>{
                                            return <option key={index} value={item.name}>{item.name}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="mb-3 form-group col-md-12">
                            <label className="form-label">Status</label>
                            <select className="form-control" name="Status" id="Status" value={props.formData.Status} onChange={props.onEdit}>
                                <option value="">Select Status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">In-Active</option>
                            </select>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}