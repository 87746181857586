import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import JoditEditor from "jodit-react";
import {MailTemplates} from "../../../resources/constants";

function PendingApplications(props) {
    const user = props.loginData[0];
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [selectedID, setSelectedID] = useState([])
    const [settingsList, setSettingsList] = useState([])
    const [yearsServed] = useState([])
    const [selectedEmail, setSelectedEmail] = useState([])
    const [selectedName, setSelectedName] = useState([])
    const [selectedPhone, setSelectedPhone] = useState([])
    const header = ["S/N", <input type="checkbox"
                                  id="checkAll"
                                  className="checkAll"
                                  onChange={()=>checkAll()}/>, "APPID", "FirstName",  "MiddleName",  "LastName", "DateOFBirth", "Gender", "State", "LGA", "SSCE",  "SSCE Year", "Subject 1", "Subject 2", "Subject 3", "Subject 4", "Subject 5", "Subject 6", "Subject 7", "Subject 8", "Subject 9", "Entry Level", "Programme", "Phone", "Email", "Institution Name", "Course of Study", "Class", "Awarded Year"];
    const [formData, setFormData] = useState({
        Admission_Session: "",
        Admission_Date: "",
        Admission_Year: "",
        msgTitle: "",
        msgSubject: "",
        msgTo: "",
        msgBody: "",
        NotificationType: "",
        Staff: `${user.FirstName} ${user.MiddleName} ${user.Surname}`,
        Designation: `${user.Designation}`,
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    useEffect( () => {
        getData();
        yearsJoined();
    }, [""]);

    const onEdit = (e) => {
        if (e.target.id === "NotificationType"){
            if (e.target.value === "EMAIL"){
                const email = MailTemplates("admission", formData);
                setFormData({
                    ...formData,
                    msgTitle: email.title,
                    msgSubject: email.subject,
                    msgBody: email.body,
                    [e.target.id]: e.target.value,
                })
            }else if (e.target.value === "SMS"){
                setFormData({
                    ...formData,
                    msgTitle: "Admission Offer",
                    msgSubject: "Admission Offer",
                    msgBody: `Congratulations! \nYou have been offered admission into Kaduna Polytechnic through Open Distance & Flexible e-Learning (ODFeL) to study HND Computer Science. Visit https://kadpolyodfel.ng/admission/check-admission-status to check your admission using your Application Number. For more info Contact us: 08036065397, 08068067404.`,
                    [e.target.id]: e.target.value,
                })
            }else{
                setFormData({
                    ...formData,
                    msgTitle: "",
                    msgSubject:"",
                    msgBody: "",
                    [e.target.id]: e.target.value,
                })
            }
        }else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    }

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/academic/settings/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSettingsList(result.data);
                }else{
                    setSettingsList([]);
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

        await axios.get(`${serverLink}staff/registration/admission/pending`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }else{
                    setStudentList([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const unCheckAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');
        for (let i = 0; i < checkBox.length ; i++) {
            if (checkBox[i].checked) {
                checkBox[i].checked = false;
            }
        }
    }

    const checkAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');

        for (let i = 0; i < checkBox.length ; i++) {
            let applicantEmail = checkBox[i].getAttribute("data_email");
            let applicantPhone = checkBox[i].getAttribute("data_phone");
            let applicantID = checkBox[i].getAttribute("data_id");
            let applicantName = checkBox[i].getAttribute("data_name");
            if (!checkBox[i].checked) {
                checkBox[i].checked = true;
                setSelectedID(prevState => [...prevState, applicantID])
                setSelectedEmail(prevState => [...prevState, applicantEmail])
                setSelectedPhone(prevState => [...prevState, applicantPhone])
                setSelectedName(prevState => [...prevState, applicantName])
            }else{
                checkBox[i].checked = false;
                setSelectedID([])
                setSelectedEmail([])
                setSelectedPhone([])
                setSelectedName([])
            }
        }

    }

    const onCheck = (e) => {
        let applicantEmail = e.target.getAttribute("data_email");
        let applicantPhone = e.target.getAttribute("data_phone");
        let applicantID = e.target.getAttribute("data_id");
        let applicantName = e.target.getAttribute("data_name");

        if (e.target.checked){
            setSelectedID(prevState => [...prevState, applicantID])
            setSelectedEmail(prevState => [...prevState, applicantEmail])
            setSelectedPhone(prevState => [...prevState, applicantPhone])
            setSelectedName(prevState => [...prevState, applicantName])
        }else {
            let newSelected = selectedID.filter(e=> e.toString() !== applicantID.toString())
            let newSelectedEmail = selectedEmail.filter(e=> e.toString() !== applicantEmail.toString())
            let newSelectedPhone = selectedEmail.filter(e=> e.toString() !== applicantPhone.toString())
            let newSelectedName = selectedName.filter(e=> e.toString() !== applicantName.toString())
            setSelectedID(newSelected)
            setSelectedEmail(newSelectedEmail)
            setSelectedPhone(newSelectedPhone)
            setSelectedName(newSelectedName)
        }

    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">
                            <input type="checkbox"
                                   id="checkItem"
                                   data_email={item.EMAIL}
                                   data_phone={item.TELEPHONE}
                                   data_id={item.APPID}
                                   data_name={`${item.FIRST_NAME} ${item.MIDDLE_NAME} ${item.LAST_NAME}`}
                                   data={JSON.stringify(item)}
                                   className="checkItem"
                                   name="checkItem"
                                   value={item.APPID}
                                   onChange={onCheck} />
                        </td>
                        <td className="text-xs font-weight-bold">{item.APPID}</td>
                        <td className="text-xs font-weight-bold">{item.FIRST_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.MIDDLE_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.LAST_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.DOB}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.STATE}</td>
                        <td className="text-xs font-weight-bold">{item.LGA}</td>
                        <td className="text-xs font-weight-bold">{item.Exam_Type}</td>
                        <td className="text-xs font-weight-bold">{item.Exam_Year}</td>
                        <td className="text-xs font-weight-bold">{item.subject1}</td>
                        <td className="text-xs font-weight-bold">{item.subject2}</td>
                        <td className="text-xs font-weight-bold">{item.subject3}</td>
                        <td className="text-xs font-weight-bold">{item.subject4}</td>
                        <td className="text-xs font-weight-bold">{item.subject5}</td>
                        <td className="text-xs font-weight-bold">{item.subject6}</td>
                        <td className="text-xs font-weight-bold">{item.subject7}</td>
                        <td className="text-xs font-weight-bold">{item.subject8}</td>
                        <td className="text-xs font-weight-bold">{item.subject9}</td>
                        <td className="text-xs font-weight-bold">{item.ENTRY_LEVEL}</td>
                        <td className="text-xs font-weight-bold">{item.DEPARTMENT}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold">{item.EMAIL}</td>
                        <td className="text-xs font-weight-bold">{item.INSTITUTION ?? "NULL"}</td>
                        <td className="text-xs font-weight-bold">{item.CLASS_OF_DEGREE ?? "NULL"}</td>
                        <td className="text-xs font-weight-bold">{item.CGPA ?? "NULL"}</td>
                        <td className="text-xs font-weight-bold">{item.YEAR ?? "NULL"}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const yearsJoined = () => {
        for (let i = 2020; i <= new Date().getFullYear(); i++) {
            yearsServed.push(i);
        }
    }

    const onSubmit = async () => {
        if (selectedID.length < 1) {
            showAlert("EMPTY FIELD", "Please select at least one applicant", "error");
            return false;
        }

        if (formData.Admission_Date.toString().trim() === ""){
            showAlert("EMPTY FIELD", "Please select admission date", "error");
            return false;
        }

        if (formData.Admission_Session.toString().trim() === ""){
            showAlert("EMPTY FIELD", "Please select admission session", "error");
            return false;
        }

        if (formData.Admission_Year.toString().trim() === ""){
            showAlert("EMPTY FIELD", "Please select admission year", "error");
            return false;
        }

        let sendData = {
            ...formData,
            APPID: selectedID,
            Email: selectedEmail,
            Name: selectedName,
            Phone: selectedPhone,
        }

        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/registration/admission/offer`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    document.getElementById("closeModal").click();
                    toast.success("Admission Offered Successfully");
                    setIsFormLoading(false);
                    setFormData({
                        ...formData,
                        Admission_Session: "",
                        Admission_Date: "",
                        Admission_Year: "",
                        msgTitle: "",
                        msgSubject: "",
                        msgTo: "",
                        msgBody: "",
                        APPID: "",
                        Email: "",
                        Name: "",
                    })
                    setSelectedID([])
                    setSelectedEmail([])
                    setSelectedName([])
                    unCheckAll()
                    setTimeout(()=>{
                        window.location.reload();
                    }, 1000)
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Pending Applications
                                </h2>
                            </div>
                            {
                                selectedID.length > 0 ?
                                    <div className="col-auto ms-auto d-print-none">
                                        <div className="btn-list">

                                            <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                               onClick={()=>{
                                                   const email = MailTemplates("admission", formData);
                                                   setFormData({
                                                       ...formData,
                                                       NotificationType: "",
                                                       msgTitle: email.title,
                                                       msgSubject: email.subject,
                                                       msgBody: email.body
                                                   })
                                               }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <line x1="12" y1="5" x2="12" y2="19"/>
                                                    <line x1="5" y1="12" x2="19" y2="12"/>
                                                </svg>
                                                Offer Admission
                                            </a>
                                            <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                               data-bs-target="#modal-report" aria-label="Create new report">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <line x1="12" y1="5" x2="12" y2="19"/>
                                                    <line x1="5" y1="12" x2="19" y2="12"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <h3 className="card-title">Pending Applications Report</h3>
                                    <DataTable header={header} body={showTable()} title="Pending Applications Report" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Offer Admission</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <form className="row">


                                    <div className="form-group mb-3 col-md-6">
                                        <label className='form-label' >Admission Date</label>
                                        <input type="date" className="form-control" onChange={onEdit} id="Admission_Date" value={formData.Admission_Date} required />
                                    </div>

                                    <div className="form-group mb-3 col-md-6">
                                        <label className='form-label' >Admission Session</label>
                                        <select
                                            className="form-control form-control-solid"
                                            name="Admission_Session"
                                            id="Admission_Session"
                                            value={formData.Admission_Session}
                                            onChange={onEdit}
                                        >
                                            <option value="">Select Session</option>
                                            {
                                                settingsList.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                                                    return  (<option key={index} value={item.Session}>{item.Session} {item.Status.toString() === "1" ? " (Active Session)" : ""}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group mb-3 col-md-12">
                                        <label className='form-label' >Admission Year</label>
                                        <select
                                            className="form-control form-control-solid"
                                            name="Admission_Year"
                                            id="Admission_Year"
                                            value={formData.Admission_Year}
                                            onChange={onEdit}
                                        >
                                            <option value="">Select Year</option>
                                            {
                                                yearsServed.sort((a, b) => b - a).map((item, index)=>{
                                                    return  (<option key={index} value={item}>{item}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group mb-3 col-md-12">
                                        <label className='form-label' >Notification Type</label>
                                        <select
                                            className="form-control form-control-solid"
                                            name="NotificationType"
                                            id="NotificationType"
                                            value={formData.NotificationType}
                                            onChange={onEdit}
                                        >
                                            <option value="">Select Notification Type</option>
                                            <option value="EMAIL">Email</option>
                                            <option value="SMS">SMS</option>
                                            <option value="No Notification">No Notification</option>
                                        </select>
                                    </div>

                                    <hr />
                                    {
                                        formData.NotificationType === "" ?
                                        <></> :
                                            <>
                                                {
                                                    formData.NotificationType === "EMAIL" ?
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className='form-label' >Subject</label>
                                                                <input type="text" className="form-control" placeholder='Enter email subject' onChange={onEdit} id="msgSubject" value={formData.msgSubject}  />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label className='form-label' >Title</label>
                                                                <input type="text" className="form-control" placeholder='Enter email title' onChange={onEdit} id="msgTitle" value={formData.msgTitle}  />
                                                            </div>
                                                            <br />
                                                            <div className="form-group mb-3" id="body" >
                                                                <label className='form-label' >Email Body</label>
                                                                <JoditEditor
                                                                    value={formData.msgBody}
                                                                    tabIndex={1}
                                                                    onChange={(e) => {
                                                                        setFormData({
                                                                            ...formData, msgBody: e
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </> : formData.NotificationType === "SMS" ?
                                                        <>
                                                            <div className="form-group mb-3" id="body" >
                                                                <label className='form-label' >Message Body</label>
                                                                <textarea
                                                                    cols={3}
                                                                    rows={8}
                                                                    className="form-control"
                                                                    value={formData.msgBody}
                                                                    onChange={(e) => {
                                                                        setFormData({
                                                                            ...formData, msgBody: e.target.value
                                                                        })
                                                                    }}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </> : <></>
                                                }
                                            </>

                                    }


                                    <hr />
                                    {
                                        IsFormLoading ?
                                            <button className="btn btn-success float-right w-100" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                 Loading, please wait...
                                            </button>
                                            :
                                            <div className="input-group mb-3">
                                                <button type='button' className='btn btn-md btn-success w-100' onClick={onSubmit}>
                                                    Admit
                                                </button>
                                            </div>
                                    }
                                </form>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(PendingApplications);
