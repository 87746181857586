import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import "./style.css";
import {formatDateAndTime} from "../../../resources/constants";

function DropOfCourseAcknowledgement(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [courses, setCourse] = useState([])
    const [requestData, setRequestData] = useState(props.requestData)

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/drop-of-course/request/courses/${requestData.EntryID}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let courses = result.data;
                    setCourse(courses);
                }else{
                    setCourse([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Defer/Drop Of Course Form
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="text-center" style={{marginTop: '-40px'}}>
                                            <div className="col-md-12">
                                                <img src={require("../../../images/logo.png")} width="70" height="70"/>
                                            </div>
                                            <h2 style={{fontWeight: 'bold'}}>KADUNA POLYTECHNIC</h2>
                                            <h6 style={{marginTop: '-10px'}}>Polytechnic Road P.M.B 2021, Tudun Wada, Kaduna State</h6>
                                            <h3 style={{fontWeight: 'bold', marginTop: '-10px'}} className="mb-3">Deferment/Drop of Course Form</h3>
                                        </div>
                                        <table className="table table-bordered" style={{fontSize: '12px'}}>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '40%'}}>Student Name:</td>
                                                            <td className="text-secondary fw-bold"> {requestData.StudentName}</td>
                                                        </tr>
                                                    </td>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '35%'}}>Student ID:</td>
                                                            <td className="text-secondary fw-bold"> {requestData.StudentID}</td>
                                                        </tr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '35%'}}>Programme:</td>
                                                            <td className="text-secondary fw-bold">{requestData.Programme}</td>
                                                        </tr>
                                                    </td>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '35%'}}>Level:</td>
                                                            <td className="text-secondary fw-bold text-center"> 300 Level</td>
                                                        </tr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '50%'}}>Session Applied:</td>
                                                            <td className="text-secondary fw-bold">{requestData.CurrentSession}</td>
                                                        </tr>
                                                    </td>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '50%'}}>Return Session:</td>
                                                            <td className="text-secondary fw-bold text-center"> {requestData.ReturnSession}</td>
                                                        </tr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '50%'}}>Semester Applied:</td>
                                                            <td className="text-secondary fw-bold">{requestData.CurrentSemester.toString() === "1" ? 'First' : 'Second'}</td>
                                                        </tr>
                                                    </td>
                                                    <td>
                                                        <tr>
                                                            <td style={{width: '50%'}}>Return Semester:</td>
                                                            <td className="text-secondary fw-bold text-center"> {requestData.ReturnSemester.toString() === "1" ? 'First' : 'Second'}</td>
                                                        </tr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <tr>
                                                            <td style={{width: '68%'}}>Reason for deffer/dropping:</td>
                                                            <td className="text-secondary fw-bold">{requestData.Reason}</td>
                                                        </tr>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>

                                        {
                                            courses.length > 0 && <div className="mt-2">
                                            <h5>Dropped/deferred course(s)</h5>
                                                <table style={{fontSize: '11px'}} id="example" className="table table-bordered table-striped"  style={{fontSize: '11px'}}>
                                                    <thead>
                                                    <tr>
                                                        <th>Course Code</th>
                                                        <th>Course Title</th>
                                                        <th>Level</th>
                                                        <th>Semester</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        courses.length > 0 && courses.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.COURSE_CODE}</td>
                                                                    <td>{item.COURSE_TITLE}</td>
                                                                    <td>{item.LEVEL}00</td>
                                                                    <td>{item.SEMESTER === 1 ? "Fist" : "Second"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>

                                    {
                                        requestData.StatusBy ?
                                            <>
                                                <p className="text-muted mt-3" style={{textAlign: 'justify'}}>This is to certify that I, {requestData.StaffName}, in the capacity of Director at Open, Distance & Flexible Learning (ODFeL)
                                                    Kaduna Polytechnic, hereby approve the request for deffer/dropping the above course(s).</p>
                                                <p>Approval Date: {formatDateAndTime(requestData.StatusDate, 'date')}</p>

                                                <p>Director's Signature: ____________________________</p>
                                            </>
                                            : <div className="alert alert-important alert-danger alert-dismissible mt-3" role="alert">Pending approval from the Director.</div>
                                    }

                                    <div className="divide-x"></div>

                                    {
                                        requestData.RegistrarApproval ?
                                            <>
                                                <p className="text-muted mt-5" style={{textAlign: 'justify'}}>This is to certify that I, {requestData.RegistrarName}, the Registrar of Kaduna Polytechnic, have reviewed and approved the student's request to deffer/drop the above course(s).</p>
                                                <p>Approval Date: {formatDateAndTime(requestData.RegistrarApprovedDate, 'date')}</p>

                                                <p>Registrar's Signature: ____________________________</p>
                                            </> : <div className="alert alert-important alert-danger alert-dismissible mt-3" role="alert">Pending approval from the Registrar.</div>
                                    }


                                </div>
                                <div className="mt-3 col-md-3 offset-sm-4 mb-3 btnHide" role="alert"><button type="button" className="btn btn-primary  form-control" onClick={()=>window.print()}>Print</button></div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        requestData: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(DropOfCourseAcknowledgement);
