import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataLoader from "../common/dataLoader/dataLoader";

const ChatComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState(null);

    const studentResult = [
        {
            Semester: "First",
            ModuleCode: "COM806",
            ModuleTitle: "Advanced Computer Architecture",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "72",
            ExamScore: "41",
            Total: "60",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM801",
            ModuleTitle: "Advanced Operating System",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "C",
            CAOneScore: "70",
            ExamScore: "42",
            Total: "59",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "MDA807",
            ModuleTitle: "Distributed Computing",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "C",
            CAOneScore: "66.67",
            ExamScore: "37",
            Total: "55",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "SCI801",
            ModuleTitle: "Management and Entrepreneurship",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "C",
            CAOneScore: "53",
            ExamScore: "65",
            Total: "58",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM816",
            ModuleTitle: "Organisation of Programming Languages",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "62",
            ExamScore: "58",
            Total: "60",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM805",
            ModuleTitle: "Software Engineering I",
            Session: "2022",
            SchoolSemester: "22B",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "75",
            ExamScore: "56",
            Total: "67",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM803",
            ModuleTitle: "Advanced Algorithms & Complexity Analysis",
            Session: "2022",
            SchoolSemester: "22C",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "A",
            CAOneScore: "77",
            ExamScore: "77",
            Total: "77",
            IsLecturerRated: "1"
        },
        {
            Semester: "Second",
            ModuleCode: "COM804",
            ModuleTitle: "Advanced Data Communication and Networks",
            Session: "2022",
            SchoolSemester: "22C",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "C",
            CAOneScore: "48",
            ExamScore: "35",
            Total: "54",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM809",
            ModuleTitle: "Advanced Database System",
            Session: "2022",
            SchoolSemester: "22C",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "80",
            ExamScore: "54.29",
            Total: "62",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "MIS806",
            ModuleTitle: "Advanced Information Systems",
            Session: "2022",
            SchoolSemester: "22C",
            StudentLevel: "800",
            Status: "Fail",
            CreditLoad: "3",
            StudentGrade: "F",
            CAOneScore: "62",
            ExamScore: "30",
            Total: "40",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "SCI802",
            ModuleTitle: "ICT & Research Methodology",
            Session: "2022",
            SchoolSemester: "22C",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "C",
            CAOneScore: "71",
            ExamScore: "52",
            Total: "58",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "MIS806",
            ModuleTitle: "Advanced Information Systems",
            Session: "2023",
            SchoolSemester: "23A",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "70",
            ExamScore: "61.67",
            Total: "64",
            IsLecturerRated: "1"
        },
        {
            Semester: "First",
            ModuleCode: "COM815",
            ModuleTitle: "Artificial Intelligence",
            Session: "2023",
            SchoolSemester: "23A",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "90",
            ExamScore: "48",
            Total: "61",
            IsLecturerRated: "1"
        },
        {
            Semester: "Third",
            ModuleCode: "COM845",
            ModuleTitle: "Computer Simulation and Modelling",
            Session: "2023",
            SchoolSemester: "23A",
            StudentLevel: "800",
            Status: "Pass",
            CreditLoad: "3",
            StudentGrade: "B",
            CAOneScore: "72",
            ExamScore: "56",
            Total: "61",
            IsLecturerRated: "1"
        },
        {
            Semester: "Third",
            ModuleCode: "COM843",
            ModuleTitle: "Fundamentals of Machine Learning",
            Session: "2023",
            SchoolSemester: "23A",
            StudentLevel: "800",
            Status: "Fail",
            CreditLoad: "3",
            StudentGrade: "F",
            CAOneScore: "48",
            ExamScore: "40",
            Total: "42",
            IsLecturerRated: "1"
        }
    ];

    const studentData2 = [
        {
            SN: 2,
            APPID: "209117773710",
            STUDENTID: "KPT/ODFeL/CST/20/009",
            EMAIL: "auwalgombe89@gmail.com",
            FIRST_NAME: "AUWAL",
            MIDDLE_NAME: "",
            LAST_NAME: "ABDULLAHI",
            DOB: "1989-06-05",
            GENDER: "Male",
            BLOOD_GROUP: "0+",
            TELEPHONE: "07067925211",
            PROG_ID: 5,
            ADMISSION_BATCH: 0,
            APP_STATUS: "Active",
            ACCEPT_STATUS: "1",
            CLEARANCE_STATUS: "1",
            IS_STUDENT: "1",
            DEPART_ID: "13",
            STUDENT_LEVEL: "4",
            PROG_CHANGE: "0",
            SHORTLIST: "Pending",
            IS_ACTIVE: 1,
            STUDENT_STATUS: "Active",
            ADMISSION_DATE: "2020-11-09",
            ADMISSION_SESSION: "2021/2022",
            ADMITTED_BY: null,
            ADMISSION_YEAR: 2021,
            MODE_OF_ENTRY: null,
            PICTURE: "209117773710.jpg",
            DATE: "2020-11-09 09:52:00",
        },{
            SN: 3,
            APPID: "209117773723",
            STUDENTID: "KPT/ODFeL/CST/20/0032",
            EMAIL: "auwalgombe89@gmail.com",
            FIRST_NAME: "Adam",
            MIDDLE_NAME: "",
            LAST_NAME: "Yau",
            DOB: "1989-06-05",
            GENDER: "Male",
            BLOOD_GROUP: "0+",
            TELEPHONE: "07067925211",
            PROG_ID: 5,
            ADMISSION_BATCH: 0,
            APP_STATUS: "Active",
            ACCEPT_STATUS: "1",
            CLEARANCE_STATUS: "1",
            IS_STUDENT: "1",
            DEPART_ID: "13",
            STUDENT_LEVEL: "4",
            PROG_CHANGE: "0",
            SHORTLIST: "Pending",
            IS_ACTIVE: 1,
            STUDENT_STATUS: "Active",
            ADMISSION_DATE: "2020-11-09",
            ADMISSION_SESSION: "2021/2022",
            ADMITTED_BY: null,
            ADMISSION_YEAR: 2021,
            MODE_OF_ENTRY: null,
            PICTURE: "209117773710.jpg",
            DATE: "2020-11-09 09:52:00",
        },{
            SN: 4,
            APPID: "2091177737222",
            STUDENTID: "KPT/ODFeL/CST/20/0033",
            EMAIL: "auwalgombe89@gmail.com",
            FIRST_NAME: "Aisha",
            MIDDLE_NAME: "",
            LAST_NAME: "Yau",
            DOB: "1989-06-05",
            GENDER: "Female",
            BLOOD_GROUP: "0+",
            TELEPHONE: "07067925211",
            PROG_ID: 5,
            ADMISSION_BATCH: 0,
            APP_STATUS: "Active",
            ACCEPT_STATUS: "1",
            CLEARANCE_STATUS: "1",
            IS_STUDENT: "1",
            DEPART_ID: "13",
            STUDENT_LEVEL: "4",
            PROG_CHANGE: "0",
            SHORTLIST: "Pending",
            IS_ACTIVE: 1,
            STUDENT_STATUS: "Active",
            ADMISSION_DATE: "2020-11-09",
            ADMISSION_SESSION: "2021/2022",
            ADMITTED_BY: null,
            ADMISSION_YEAR: 2021,
            MODE_OF_ENTRY: null,
            PICTURE: "209117773710.jpg",
            DATE: "2020-11-09 09:52:00",
        },
        // (...repeat the structure for other objects in the array)
    ];

    const studentAttendance = [
        {
            ModuleCode: "GEN201",
            ModuleDescription: "Entrepreneurship 1",
            Attended: 2
        },
        {
            ModuleCode: "GEN203",
            ModuleDescription: "Peace Studies & Conflict Resolution 1",
            Attended: 4
        },
        {
            ModuleCode: "PSY201",
            ModuleDescription: "General Experimental Psychology 1",
            Attended: 0
        },
        {
            ModuleCode: "PSY203",
            ModuleDescription: "Physiological Psychology I",
            Attended: 7
        },
        {
            ModuleCode: "PSY207",
            ModuleDescription: "Developmental Psychology I (Childhood & Adolescences)",
            Attended: 0
        },
        {
            ModuleCode: "PSY209",
            ModuleDescription: "Psychology of Ethnicity & Ethnic Groups",
            Attended: 0
        },
        {
            ModuleCode: "PSY211",
            ModuleDescription: "Principles of Criminology and Juvenile Delinquency",
            Attended: 4
        },
        {
            ModuleCode: "SOC203",
            ModuleDescription: "Statistics for Social Sciences",
            Attended: 0
        }
    ];

    const studentPayment = [
        {
            PaymentID: "23C3637384037",
            SchoolTrimester: "23C",
            TransactionID: null,
            StudentLevel: "400",
            StudentSemester: "Second",
            TotalExpectedAmount: "836375.5",
            AmountPaid: 836425,
            OutStandingAmount: -49.5,
            PaymentMethod: "Transfer",
            NumberOfResit: null,
            TotalResitAmount: null,
            Session: "2023",
            ResitAmountPaid: null
        }
    ];



    useEffect(() => {
        const fetchData = async () => {
            try {
                const conversation = [
                    // Add each student data as a message
                    // ...studentData.map(student => ({ "role": "assistant", "content": JSON.stringify(student) })),
                    // Add a user message instructing the assistant to analyze based on gender

                    // Student Result
                    // { "role": "user", "content": `${ JSON.stringify(studentResult)} ` },
                    // { "role": "user", "content": "Please analyze the student result based on StudentGrade, Status, SchoolSemester, and CreditLoad. And please advise the student based on the performance." },

                    // Student Attendance
                    // { "role": "user", "content": `${ JSON.stringify(studentAttendance)} ` },
                    // { "role": "user", "content": "Please analyze the student attendance based on Attended. And please advise the student based on the analysis." },

                    // Student Payment
                    { "role": "user", "content": `${ JSON.stringify(studentPayment)} ` },
                    { "role": "user", "content": "Please analyze the student payment based on SchoolTrimester, TotalExpectedAmount, AmountPaid, OutStandingAmount, PaymentMethod, Session, SchoolTrimester, StudentLevel, StudentSemester. And please advise the student based on the analysis." },
                ];

                console.log(conversation)

                const result = await axios.post('http://localhost:4480/openai/test', {
                    messages: conversation,
                    model: 'gpt-3.5-turbo',
                }).then((res)=>{
                    if (res.status === 200){
                        console.log(res)
                        setResponse(res.data.choices[0]);
                    }else{

                    }

                    setIsLoading(false)
                });


            } catch (error) {
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
            }
        };

        fetchData();
    }, []);

    return ( isLoading ? <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>  :
        <div>
            <h1>OpenAI Chat Completion Response:</h1>
            <pre>{JSON.stringify(response, null, 2)}</pre>
            <pre>{response.message.content}</pre>
        </div>
    );
};

export default ChatComponent;
