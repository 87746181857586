import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime} from "../../../resources/constants";

function CourseRegistrationReport(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [showTBL, setShowTBL] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [settingsList, setSettingsList] = useState([])
    const header = ["S/N", "STUDENTID", "Name", "Phone No.", "Programme", "Course_Code", "Course_Title", "Courses Registered"];
    const [formData, setFormData] = useState({
        Semester: "",
        Level: "",
        Session: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const getAllData = async () => {
        await axios.get(`${serverLink}staff/registration/academic/settings/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSettingsList(result.data);
                }else{
                    setSettingsList([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);

    useEffect( () => {
        if (formData.Level.toString()  !== "" && formData.Semester.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.Semester, formData.Session)
        }

    }, [formData.Level.toString()]);

    useEffect( () => {
        if (formData.Level.toString() !== "" && formData.Semester.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.Semester, formData.Session)
        }

    }, [formData.Semester.toString()]);

    useEffect( () => {
        if (formData.Level.toString() !== "" && formData.Semester.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.Semester, formData.Session)
        }

    }, [formData.Session.toString()]);

    const getData = async (level, semester, session) => {
        setIsLoading(true)
        await axios.get(`${serverLink}staff/report/registration/course/${level}/${semester}/${btoa(session)}`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }else{
                    setStudentList([]);
                }
                setIsLoading(false)
                setShowTBL(true)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENTID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.COURSE_CODE.length > 0 ? item.COURSE_CODE.map(e=><React.Fragment>{e}<br/></React.Fragment>) : "Not Registered"}</td>
                        <td className="text-xs font-weight-bold">{item.COURSE_TITLE.length > 0 ? item.COURSE_TITLE.map(e=><React.Fragment>{e}<br/></React.Fragment>) : "Not Registered"}</td>
                        <td className="text-xs font-weight-bold">{item.COUNT}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Course Registration Report
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Level and Semester</h5></div>
                                                <div className="form-group mb-3 col-md-12">
                                                    <select
                                                        className="form-control form-control-solid"
                                                        name="Session"
                                                        id="Session"
                                                        value={formData.Session}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Session</option>
                                                        {
                                                            settingsList.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                                                                return  (<option className={item.Status.toString() === "1" ? "text-success" : ""} key={index} value={item.Session}>{item.Session} {item.Status.toString() === "1" ? " (Active Session)" : ""}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <select
                                                        className="form-select"
                                                        name="Level"
                                                        id="Level"
                                                        value={formData.Level}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Level</option>
                                                        <option value="1">100 Level</option>
                                                        <option value="2">200 Level</option>
                                                        <option value="3">300 Level</option>
                                                        <option value="4">400 Level</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <select
                                                        className="form-select"
                                                        name="Semester"
                                                        id="Semester"
                                                        value={formData.Semester}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Semester</option>
                                                        <option value="1">First Semester</option>
                                                        <option value="2">Second Semester</option>
                                                    </select>
                                                </div>

                                                {
                                                    showTBL ? <DataTable header={header} body={showTable()} title="Course Registration Report" /> :
                                                        <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         className="icon alert-icon" width="24"
                                                                         height="24" viewBox="0 0 24 24"
                                                                         strokeWidth="2" stroke="currentColor"
                                                                         fill="none" strokeLinecap="round"
                                                                         strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                              fill="none"/>
                                                                        <circle cx="12" cy="12" r="9"/>
                                                                        <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                        <polyline points="11 12 12 12 12 16 13 16"/>
                                                                    </svg>
                                                                </div>
                                                                <div> Please select level and semester to show report </div>
                                                            </div>
                                                            <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(CourseRegistrationReport);
