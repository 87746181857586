import React from "react";
export default function DropOfCourseRequestForm(props) {
    return <>
        {
            props.formData.ActionType === 1 ?
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Approve Drop of Course Request</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="hr-text hr-text-left"><h2>Student/Drop of Course Details</h2></div>
                                    <ul className="ml-3" style={{marginLeft: '10px'}}>
                                        <li className="list-check">Student ID: <b>{props.formData.StudentID}</b></li>
                                        <li>Student Name: <b>{props.formData.StudentName}</b></li>
                                        <li>Programme: <b>{props.formData.Programme}</b></li>
                                        <li>Session Applied: <b>{props.formData.CurrentSession}</b></li>
                                        <li>Return Session: <b>{props.formData.ReturnSession}</b></li>
                                        <li>Semester Applied: <b>{props.formData.CurrentSemester}</b></li>
                                        <li>Return Semester: <b>{props.formData.ReturnSemester}</b></li>
                                        <li>Drop of Course Status: <b>{props.formData.Status}</b></li>
                                    </ul>
                                    <div className="list-group-item mb-3">
                                        <div className="row align-items-center">
                                            <div className="col-12"><b>Drop of Course Reason:</b></div>
                                            <br/><br/>
                                            <div className="col ">
                                                <span className="text-reset ">{props.formData.Reason} </span>
                                                <hr/>
                                                <div className="hr-text hr-text-left"><h4>Dropped Courses</h4></div>
                                                {
                                                    props.formData.IsFormLoading2 ?
                                                        <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         className="icon alert-icon" width="24"
                                                                         height="24" viewBox="0 0 24 24"
                                                                         strokeWidth="2" stroke="currentColor"
                                                                         fill="none" strokeLinecap="round"
                                                                         strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                              fill="none"/>
                                                                        <circle cx="12" cy="12" r="9"/>
                                                                        <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                        <polyline
                                                                            points="11 12 12 12 12 16 13 16"/>
                                                                    </svg>
                                                                </div>
                                                                <div> Please wait while the system is loading student course(s)
                                                                </div>
                                                            </div>
                                                            <a className="btn-close btn-close-white"
                                                               data-bs-dismiss="alert" aria-label="close"/>
                                                        </div>
                                                        :
                                                        <table style={{fontSize: '11px'}} id="example"
                                                               className="table table-bordered table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Course Code</th>
                                                                <th>Course Title</th>
                                                                <th>Level</th>
                                                                <th>Semester</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                props.formData.courses.length > 0 && props.formData.courses.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.COURSE_CODE}</td>
                                                                            <td>{item.COURSE_TITLE}</td>
                                                                            <td>{item.LEVEL}00</td>
                                                                            <td>{item.SEMESTER === 1 ? "Fist" : "Second"}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                            </tbody>
                                                        </table>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="alert alert-important alert-warning alert-dismissible" role="alert">
                                        <div className="d-flex">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon"
                                                     width="24"
                                                     height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                     stroke="currentColor"
                                                     fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M12 9v2m0 4v.01"/>
                                                    <path
                                                        d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
                                                </svg>
                                            </div>
                                            <div>
                                                Are you sure you want to approve this drop of course request?
                                            </div>
                                        </div>
                                        <a className="btn-close btn-close-white" data-bs-dismiss="alert"
                                           aria-label="close"/>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary"
                                   data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                {
                                    props.IsFormLoading ?
                                        <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2"
                                                        role="status"/> Please wait...</span>
                                        </button>
                                        :
                                        <button type="button" onClick={props.onSubmit}
                                                className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Approve
                                        </span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                props.formData.ActionType === 2 ?
                    <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reject Drop of Course Request</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="hr-text hr-text-left"><h2>Student/Drop of Course Details</h2>
                                        </div>
                                        <ul className="ml-3" style={{marginLeft: '10px'}}>
                                            <li className="list-check">Student ID: <b>{props.formData.StudentID}</b>
                                            </li>
                                            <li>Student Name: <b>{props.formData.StudentName}</b></li>
                                            <li>Programme: <b>{props.formData.Programme}</b></li>
                                            <li>Session Applied: <b>{props.formData.CurrentSession}</b></li>
                                            <li>Return Session: <b>{props.formData.ReturnSession}</b></li>
                                            <li>Semester Applied: <b>{props.formData.CurrentSemester}</b></li>
                                            <li>Return Semester: <b>{props.formData.ReturnSemester}</b></li>
                                            <li>Drop of Course Status: <b>{props.formData.Status}</b></li>
                                        </ul>
                                        <div className="list-group-item mb-3">
                                            <div className="row align-items-center">
                                                <div className="col-12"><b>Drop of Course Reason:</b></div>
                                                <br/><br/>
                                                <div className="col ">
                                                    <span className="text-reset ">{props.formData.Reason} </span>
                                                    <hr/>
                                                    <div className="hr-text hr-text-left"><h4>Dropped Courses</h4></div>
                                                    <table style={{fontSize: '11px'}} id="example"
                                                           className="table table-bordered table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Course Code</th>
                                                            <th>Course Title</th>
                                                            <th>Level</th>
                                                            <th>Semester</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            props.formData.courses.length > 0 && props.formData.courses.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.COURSE_CODE}</td>
                                                                        <td>{item.COURSE_TITLE}</td>
                                                                        <td>{item.LEVEL}00</td>
                                                                        <td>{item.SEMESTER === 1 ? "Fist" : "Second"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="alert alert-important alert-warning alert-dismissible"
                                             role="alert">
                                            <div className="d-flex">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon"
                                                         width="24"
                                                         height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                         stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M12 9v2m0 4v.01"/>
                                                        <path
                                                            d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
                                                    </svg>
                                                </div>
                                                <div>
                                                    Are you sure you want to reject this drop of course request?
                                                </div>
                                            </div>
                                            <a className="btn-close btn-close-white" data-bs-dismiss="alert"
                                               aria-label="close"/>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <a href="#" id="closeModal" className="btn btn-link link-secondary"
                                       data-bs-dismiss="modal">
                                        Cancel
                                    </a>
                                    {
                                        props.IsFormLoading ?
                                            <button type="button" className="btn btn-primary ms-auto">
                                                <span><span className="spinner-border spinner-border-sm me-2"
                                                            role="status"/> Please wait...</span>
                                            </button>
                                            :
                                            <button type="button" onClick={props.onSubmit}
                                                    className="btn btn-danger ms-auto">
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                             height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="12" y1="5" x2="12" y2="19"/>
                                        <line x1="5" y1="12" x2="19" y2="12"/>
                                    </svg>
                                         Reject
                                    </span>
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Drop of Course Request</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="list-group-item mb-3">
                                            <div className="row align-items-center">
                                                <div className="col ">
                                                    <div className="hr-text hr-text-left"><h4>Dropped Courses</h4></div>
                                                    <table style={{fontSize: '11px'}} id="example"
                                                           className="table table-bordered table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Course Code</th>
                                                            <th>Course Title</th>
                                                            <th>Level</th>
                                                            <th>Semester</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            props.formData.courses.length > 0 && props.formData.courses.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.COURSE_CODE}</td>
                                                                        <td>{item.COURSE_TITLE}</td>
                                                                        <td>{item.LEVEL}00</td>
                                                                        <td>{item.SEMESTER === 1 ? "Fist" : "Second"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        }

    </>
}