import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import Skeleton from "../common/skeleton/skeleton";
import Dashboard from "./dashboard";
import StaffDashboard from "./StaffDashboard";

function DashboardRoot(props) {

    const [IsLoading, setIsLoading] = useState(true)
    const [userType, setUserType] = useState(props.loginData[0]?.userGroup ?? []);
    return (
        userType.length < 1 ?
            <Skeleton/>
            :
            userType[0]?.GroupName.includes('Super Admin') ?
                <Dashboard/>
                :
                <StaffDashboard/>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(DashboardRoot);
