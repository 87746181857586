import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import StudentSelectOption from "../../common/dynamic-select-option/student-select-option";
import ListTile from "../../common/list-tile/list-tile";
import ApplicantSelectOption from "../../common/dynamic-select-option/applicant-select-option";
import DataTable from "../../common/data-table/data-table";
import {currencyConverter, formatDateAndTime} from "../../../resources/constants";
import {set} from "immutable";
import ProgrammeSelectOption from "../../common/dynamic-select-option/progrmme-select-option";

function ChangeStudentCourse(props) {
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [programmeDetails, setProgrammeDetails] = useState({})

    const [formData, setFormData] = useState({
        APPID: "",
        PROG_ID: "",
        NewProgrammeID: "",
        DepartmentID: "",
        NewStudentLevel: "",
        NewProgramme: "",
        School: "",
        College: "",
        Department: "",
        STUDENTID: "",
        Cart: "",
        StudentName: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        StudentSemester: "",
        StudentDepartment: "",
        StudentLevel: "",
        StudentProgramme: "",
        EmailAddress: "",
        StudentType: "",
        ChangeLevel: '0',
        PhoneNumber: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            APPID: "",
            PROG_ID: "",
            NewProgrammeID: "",
            DepartmentID: "",
            NewStudentLevel: "",
            NewProgramme: "",
            School: "",
            College: "",
            Department: "",
            STUDENTID: "",
            Cart: "",
            StudentName: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            StudentSemester: "",
            StudentDepartment: "",
            StudentLevel: "",
            StudentProgramme: "",
            EmailAddress: "",
            StudentType: "",
            ChangeLevel: '0',
            PhoneNumber: "",
        });
    }

    useEffect( () => {
        if (formData.APPID !== "" ){
            getApplicantData();
        }

    }, [formData.APPID]);

    useEffect( () => {
        if (formData.STUDENTID !== "" ){
            getStudentData();
        }

    }, [formData.STUDENTID]);

    useEffect( () => {
        if (formData.PROG_ID !== "" ){
            getNewProgrammeDetails();
        }

    }, [formData.PROG_ID]);

    const getNewProgrammeDetails = async () => {
        await axios.get(`${serverLink}staff/registration/programme/details/${formData.PROG_ID}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let data = result.data[0];
                    setProgrammeDetails(data)
                    setFormData({
                        ...formData,
                        NewProgrammeID: data.PROG_ID,
                        NewStudentLevel: data.ENTRY_LEVEL,
                        NewProgramme: data.PROGRAMME,
                        School: data.SCHOOL_NAME,
                        College: data.COLLEGE_NAME,
                        Department: data.DEPARTMENT_NAME,
                        DepartmentID: data.DEPT_ID,
                    })
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getStudentData = async () => {
        await axios.get(`${serverLink}staff/finance/student-payment-data/${btoa(formData.STUDENTID)}`)
            .then((result) => {
                if (result.data.StudentData.length > 0) {
                    let data = result.data.StudentData[0];
                    setFormData({
                        ...formData,
                        APPID: data.APPID,
                        STUDENTID: data.STUDENTID,
                        StudentName: data.FIRST_NAME+" "+data.MIDDLE_NAME+" "+data.LAST_NAME,
                        FirstName: data.FIRST_NAME,
                        MiddleName: data.MIDDLE_NAME,
                        LastName: data.LAST_NAME,
                        StudentLevel: data.STUDENT_LEVEL,
                        StudentDepartment: data.DEPART_ID,
                        StudentProgramme: data.PROGRAMME,
                        EmailAddress: data.EMAIL,
                        PhoneNumber: data.TELEPHONE,
                        Semester: "",
                        Level: "",
                        PROG_ID: "",
                        NewProgrammeID: "",
                        DepartmentID: "",
                        NewStudentLevel: "",
                        NewProgramme: "",
                        School: "",
                        College: "",
                        Department: "",
                        ChangeLevel: '0',
                    })
                    document.getElementById('student-details').style.display = "block";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getApplicantData = async () => {
        await axios.get(`${serverLink}staff/finance/applicant-payment-data/${formData.APPID}`)
            .then((result) => {
                if (result.data.StudentData.length > 0) {
                    let data = result.data.StudentData[0];
                    setFormData({
                        ...formData,
                        APPID: data.APPID,
                        STUDENTID: data.STUDENTID,
                        StudentName: data.FIRST_NAME+" "+data.MIDDLE_NAME+" "+data.LAST_NAME,
                        FirstName: data.FIRST_NAME,
                        MiddleName: data.MIDDLE_NAME,
                        LastName: data.LAST_NAME,
                        StudentLevel: data.STUDENT_LEVEL,
                        StudentDepartment: data.DEPART_ID,
                        StudentProgramme: data.PROGRAMME,
                        EmailAddress: data.EMAIL,
                        PhoneNumber: data.TELEPHONE,
                        Semester: "",
                        Level: "",
                        PROG_ID: "",
                        NewProgrammeID: "",
                        DepartmentID: "",
                        NewStudentLevel: "",
                        NewProgramme: "",
                        School: "",
                        College: "",
                        Department: "",
                        ChangeLevel: '0',
                    })
                    document.getElementById('student-details').style.display = "block";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onChangeLevel = (e) => {

        if (e.target.id === "ChangeLevel") {
            if (e.target.checked) {
                setFormData({...formData, [e.target.id]: "1",});
            } else {
                setFormData({...formData, [e.target.id]: "0",});
            }
        }
    }

        const onEdit = (e) => {

        if (e.target.id === "ChangeLevel"){
            if(e.target.checked){
                setFormData({ ...formData, [e.target.id]: "1",  });
            }else{
                setFormData({ ...formData, [e.target.id]: "0",  });
            }
        }

        if (e.target.id === "StudentType"){
            setFormData({
                ...formData,
                APPID: "",
                STUDENTID: "",
            })
            if (e.target.value !== ""){
                document.getElementById('student-select').style.display = "block";
                document.getElementById('student-details').style.display = "none";
            }else{
                document.getElementById('student-details').style.display = "none";
                document.getElementById('student-select').style.display = "none";
            }

        }

        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });

    }

    const onSubmit = async () => {
        if (formData.StudentType === "Student"){
            if (formData.STUDENTID.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select student", "error");
                return false;
            }
        }else{
            if (formData.APPID.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select student", "error");
                return false;
            }
        }


        if (formData.PROG_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select programme", "error");
            return false;
        }

        if (formData.NewStudentLevel.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select student Level", "error");
            return false;
        }

        let sendData = {
            ...formData,
        }

        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/registration/programme/course/change`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Course Changed Successfully");
                    setIsFormLoading(false);
                    if (formData.StudentType === "Student"){
                        getStudentData()
                    }else {
                        getApplicantData()
                    }
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong posting the payment. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Change Student Course/Level
                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <select
                                                                className="form-select form-control"
                                                                name="StudentType"
                                                                id="StudentType"
                                                                value={formData.StudentType}
                                                                onChange={onEdit}
                                                            >
                                                                <option value="">Select Student Type</option>
                                                                <option value="Applicant">Applicant</option>
                                                                <option value="Student">Student</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group mb-3" id="student-select"  style={{display: 'none'}}>
                                                            {
                                                                formData.StudentType === "Applicant" ?
                                                                    <ApplicantSelectOption formData={formData} setFormData={setFormData} />
                                                                    :
                                                                    <StudentSelectOption formData={formData} setFormData={setFormData} />
                                                            }
                                                        </div>

                                                        <div id="student-details" style={{display: 'none'}}>
                                                            <div className="hr-text hr-text-left"><h5>Student Details</h5></div>
                                                            <ListTile caption="Student Name" name={formData.StudentName}/>
                                                            <ListTile caption="Student ID" name={formData.STUDENTID}/>
                                                            <ListTile caption="Programme" name={formData.StudentProgramme}/>
                                                            <ListTile caption="Current Level" name={formData.StudentLevel+"00 Level"}/>
                                                            <ListTile caption="Phone Number" name={formData.PhoneNumber}/>
                                                            <ListTile caption="Email Address" name={formData.EmailAddress}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-9" id="registration-content">
                                                        {
                                                            formData.APPID === "" ?
                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="icon alert-icon" width="24"
                                                                                 height="24" viewBox="0 0 24 24"
                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                 fill="none" strokeLinecap="round"
                                                                                 strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"/>
                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                <polyline
                                                                                    points="11 12 12 12 12 16 13 16"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div> Please select student to change course
                                                                        </div>
                                                                    </div>
                                                                    <a className="btn-close btn-close-white"
                                                                       data-bs-dismiss="alert" aria-label="close"/>
                                                                </div>
                                                                :
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Programme</h5></div>
                                                                        <div className="form-group mb-3">
                                                                            <ProgrammeSelectOption  formData={formData} setFormData={setFormData} />
                                                                        </div>
                                                                        {
                                                                            formData.PROG_ID !=="" ?
                                                                                <>
                                                                                    {
                                                                                        formData.ChangeLevel.toString() === "1" ?
                                                                                            <div className="form-group mb-3">
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    name="NewStudentLevel"
                                                                                                    id="NewStudentLevel"
                                                                                                    value={formData.NewStudentLevel}
                                                                                                    onChange={onEdit}
                                                                                                >
                                                                                                    <option value="">Select Level</option>
                                                                                                    <option value="100">100 Level</option>
                                                                                                    <option value="200">200 Level</option>
                                                                                                    <option value="300">300 Level</option>
                                                                                                    <option value="400">400 Level</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="list-group-item mb-3">
                                                                                                <div className="row align-items-center">
                                                                                                    <div className="col-auto"><input type="checkbox" id="ChangeLevel"  name="ChangeLevel" onChange={onChangeLevel} className="form-check-input"/></div>
                                                                                                    <div className="col text-truncate">
                                                                                                        <span className="text-reset d-block">Change Student Level ?</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                    }

                                                                                    <div className="hr-text hr-text-left mt-5"><h2>New Course Details</h2></div>
                                                                                    <ul className="ml-3  alert alert-important alert-info alert-dismissible" role="alert" style={{marginLeft: '10px'}}>
                                                                                        <li className="list-check">New Programme:  <b>{formData.NewProgramme}</b></li>
                                                                                        <li>New Student Level: <b>{formData.NewStudentLevel}</b> </li>
                                                                                        <li>School:  <b>{formData.School}</b></li>
                                                                                        <li>College:  <b>{formData.College}</b></li>
                                                                                        <li>Department:  <b>{formData.Department}</b></li>
                                                                                    </ul>

                                                                                    {
                                                                                        IsFormLoading ?
                                                                                            <div className="text-center mb-3 mt-5 col-md-4 offset-sm-4">
                                                                                                <button type="button" className="btn btn-primary ms-auto form-control">
                                                                                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="text-center mb-3 mt-5 col-md-4 offset-sm-4">
                                                                                                <button
                                                                                                    className="btn btn-primary form-control"
                                                                                                    id="btn_register_courses"
                                                                                                    type="button"
                                                                                                    onClick={onSubmit}
                                                                                                >SUBMIT
                                                                                                </button>
                                                                                            </div>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                                    <div className="d-flex">
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                 className="icon alert-icon" width="24"
                                                                                                 height="24" viewBox="0 0 24 24"
                                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                                 fill="none" strokeLinecap="round"
                                                                                                 strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                                      fill="none"/>
                                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                                <polyline points="11 12 12 12 12 16 13 16"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div> Please select programme to proceed.  </div>
                                                                                    </div>
                                                                                    <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ChangeStudentCourse);
