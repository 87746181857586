import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import DepartmentSelectOption from "../../common/dynamic-select-option/department-select-option";

function Progression(props) {
    const [IsLoading, setIsLoading] = useState(false)
    const [showTBL, setShowTBL] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [settingsList, setSettingsList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedStudentList, setSelectedStudentList] = useState([])
    const header = ["S/N", "STUDENTID", "Name", "Gender", "Programme", "Email Address", "Phone No.", <input type="checkbox"
                                                                                                            id="checkAll"
                                                                                                            className="checkAll"
                                                                                                            onChange={()=>checkAll()}/>];
    const [formData, setFormData] = useState({
        DEPT_ID: "",
        Session: "",
        Level: "",
        Programme: "",
        ProgressionLevel: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const getAllData = async () => {
        await axios.get(`${serverLink}staff/registration/academic/settings/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSettingsList(result.data);
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);

    useEffect( () => {
        if (formData.Level.toString()  !== "" && formData.DEPT_ID.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.DEPT_ID, formData.Session)
        }

    }, [formData.Level.toString()]);

    useEffect( () => {
        if (formData.Level.toString() !== "" && formData.DEPT_ID.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.DEPT_ID, formData.Session)
        }

    }, [formData.DEPT_ID.toString()]);

    useEffect( () => {
        if (formData.Level.toString() !== "" && formData.DEPT_ID.toString() !== "" && formData.Session.toString() !== ""){
            getData(formData.Level, formData.DEPT_ID, formData.Session)
        }

    }, [formData.Session.toString()]);

    const getData = async (level, programme, session) => {
        setIsLoading(true)
        await axios.get(`${serverLink}staff/registration/progression/${programme}/${level}/${btoa(session)}`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }else{
                    setStudentList([])
                }
                setSelectedStudentList([])
                setIsLoading(false)
                setShowTBL(true)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const checkAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');

        for (let i = 0; i < checkBox.length ; i++) {
            let StudentID = checkBox[i].getAttribute("data_id");
            if (!checkBox[i].checked) {
                checkBox[i].checked = true;
                setSelectedStudentList(prevState => [...prevState, StudentID])
            }else{
                checkBox[i].checked = false;
                setSelectedStudentList([])
            }
        }

    }

    const onCheck = (e) => {
        let StudentID = e.target.getAttribute("data_id");
        if (e.target.checked){
            setSelectedStudentList(prevState => [...prevState, StudentID])
        }else {
            let Student = selectedStudentList.filter(e => e.toString() !== StudentID.toString())
            setSelectedStudentList(Student)
        }
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENTID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.EMAIL}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold"><input type="checkbox"
                                                                        id="checkItem"
                                                                        data_id={item.STUDENTID}
                                                                        className="checkItem"
                                                                        name="checkItem"
                                                                        onChange={onCheck} /></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (selectedStudentList.length < 1) {
            showAlert("EMPTY FIELD", "Please select al least 1 student to progress", "error");
            return false;
        }

        if (formData.DEPT_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select programme", "error");
            return false;
        }
        if (formData.Level.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select level", "error");
            return false;
        }
        if (formData.Session.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select session", "error");
            return false;
        }
        if (formData.ProgressionLevel.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select progression level", "error");
            return false;
        }

        let sendData = {
            ...formData,
            StudentID: selectedStudentList,
        }

        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/registration/progression/run`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Student Progressed Successfully");
                    setIsFormLoading(false);
                    setSelectedStudentList([])
                    setStudentList([])
                    getData(formData.Level, formData.DEPT_ID, formData.Session)
                    setFormData({
                        ...formData,
                        DEPT_ID: "",
                        Session: "",
                        Level: "",
                        Programme: "",
                        ProgressionLevel: "",
                    })
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong progressing student(s). Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Progression
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Course and Level</h5></div>
                                                <div className="form-group mb-3">
                                                    <DepartmentSelectOption  formData={formData} setFormData={setFormData} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <select
                                                        className="form-select"
                                                        name="Level"
                                                        id="Level"
                                                        value={formData.Level}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Level</option>
                                                        <option value="1">100 Level</option>
                                                        <option value="2">200 Level</option>
                                                        <option value="3">300 Level</option>
                                                        <option value="4">400 Level</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-3 col-md-12">
                                                    <select
                                                        className="form-control form-control-solid"
                                                        name="Session"
                                                        id="Session"
                                                        value={formData.Session}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Session</option>
                                                        {
                                                            settingsList.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                                                                return  (<option className={item.Status.toString() === "1" ? "text-success" : ""} key={index} value={item.Session}>{item.Session} {item.Status.toString() === "1" ? " (Active Session)" : ""}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div>
                                                    {
                                                        selectedStudentList.length > 0 ?
                                                            <div className="row">
                                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '35px', marginBottom: '15px'}}><h5>Select level to progress</h5></div>
                                                                <div className="form-group mb-3 col-md-8">
                                                                    <select
                                                                        className="form-select"
                                                                        name="ProgressionLevel"
                                                                        id="ProgressionLevel"
                                                                        value={formData.ProgressionLevel}
                                                                        onChange={onEdit}
                                                                    >
                                                                        <option value="">Select Progression Level</option>
                                                                        <option value="1">100 Level</option>
                                                                        <option value="2">200 Level</option>
                                                                        <option value="3">300 Level</option>
                                                                        <option value="4">400 Level</option>
                                                                    </select>
                                                                </div>
                                                                <div className="text-center mb-3 col-md-4">
                                                                    {
                                                                        IsFormLoading ?
                                                                            <button type="button" className="btn btn-primary  form-control">
                                                                                <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                                            </button>
                                                                            :
                                                                            <button
                                                                                className="btn btn-primary form-control"
                                                                                id="btn_register_courses"
                                                                                type="button"
                                                                                onClick={onSubmit}
                                                                            >RUN PROGRESSION
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    showTBL ? <>
                                                        <DataTable header={header} body={showTable()} title="Course Registration Report" />
                                                    </> :
                                                        <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         className="icon alert-icon" width="24"
                                                                         height="24" viewBox="0 0 24 24"
                                                                         strokeWidth="2" stroke="currentColor"
                                                                         fill="none" strokeLinecap="round"
                                                                         strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                              fill="none"/>
                                                                        <circle cx="12" cy="12" r="9"/>
                                                                        <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                        <polyline points="11 12 12 12 12 16 13 16"/>
                                                                    </svg>
                                                                </div>
                                                                <div> Please select Programme and level to run Progression </div>
                                                            </div>
                                                            <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Progression);
