import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {decryptData, encryptData, serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import ManageStaffForm from "./manage-staff-form";
import StateData from "../../../resources/state_and_lga.json";
import CountryData from "../../../resources/country.json";

import DataTable from "../../common/data-table/data-table";

function ManageStaff(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [departmentList, setDepartmentList] = useState([])
    const [designationList, setDesignationList] = useState([])
    const [staffTypeList, setStaffTypeList] = useState([])
    const [staffList, setStaffList] = useState([])
    const header = ["S/N", "StaffID", "Staff Name", "Gender", "Phone", "Email", "Designation", "Status", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        StaffID: "",
        Title: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Gender: "",
        DateOfBirth: "",
        MaritalStatus: "",
        Nationality: "",
        State: "",
        Lga: "",
        Religion: "",
        PhoneNumber: "",
        EmailAddress: "",
        StaffType: "",
        Designation: "",
        DepartmentCode: "",
        IsActive: "0",
        IsAcademicStaff: "0",
        IsAdmin: "0",
        ContactAddress: "",
        Research: "#",
        Facebook: '#',
        Linkedin: "#",
        Twitter: "#",
        Scholar: "#",
        Researchgate: "#",
        Academia: "#",
        Orcid: "#",
        Image: "",
        Biography: "",
        Password: "123456789",
        AddedBy: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })


    useEffect( () => {
       getStaffData();
    }, [""]);

  const  showTable = () => {
        try {
            return staffList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StaffID}</td>
                        <td className="text-xs font-weight-bold">{item.FirstName+ ' ' + item.MiddleName+ ' ' + item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.Gender}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.Designation}</td>
                        <td className="text-xs font-weight-bold">{item.IsActive.toString() ==="1" ? "Active" : "Not Active"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                            onClick={() => {
                                setFormData({
                                    ...formData,
                                    EntryID: item.EntryID,
                                    StaffID: item.StaffID,
                                    Title: item.Title,
                                    FirstName: item.FirstName,
                                    MiddleName: item.MiddleName,
                                    Surname: item.Surname,
                                    Gender: item.Gender,
                                    DateOfBirth: item.DateOfBirth,
                                    MaritalStatus: item.MaritalStatus,
                                    Nationality: item.Nationality,
                                    State: item.State,
                                    Lga: item.Lga,
                                    Religion: item.Religion,
                                    PhoneNumber: item.PhoneNumber,
                                    EmailAddress: item.EmailAddress,
                                    StaffType: item.StaffType,
                                    Designation: item.Designation,
                                    DepartmentCode: item.DepartmentCode,
                                    IsActive: item.IsActive,
                                    IsAcademicStaff: item.IsAcademicStaff,
                                    IsAdmin: item.IsAdmin,
                                    ContactAddress: item.ContactAddress,
                                    Research: item.Research,
                                    Facebook: item.Facebook,
                                    Linkedin: item.Linkedin,
                                    Twitter: item.Twitter,
                                    Scholar: item.Scholar,
                                    Researchgate: item.Researchgate,
                                    Academia: item.Academia,
                                    Orcid: item.Orcid,
                                    Image: item.Image,
                                    Biography: item.Biography,
                                    Hits: item.Hits,
                                    Password: decryptData(item.Password),
                                    AddedBy: item.AddedBy,
                                });
                                stateData();
                                StateData.map(state => {
                                    if(state.state === item.State) {
                                        state.lgas.map(lga => {
                                            setLgaList(lgaList => [...lgaList, lga])
                                        })
                                    }
                                })
                            }}>
                            Edit
                        </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const stateData = () => {
        StateData.map(state => {
            if (state.state !== 'Non-Nigerian')
                setStateList(stateList => [...stateList, state.state])
        });
    }


    const getStaffData = async () => {
        await axios
            .get(`${serverLink}staff/hr/staff/data`)
            .then((response) => {
                setDepartmentList(response.data.department);
                setDesignationList(response.data.designation);
                setStaffTypeList(response.data.staffType);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
        await axios.get(`${serverLink}staff/hr/staff/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStaffList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onEdit = (e) => {
        if (e.target.name === 'Nationality') {
            setStateList([])
            setLgaList([])
            setFormData({
                ...formData,
                State: "",
            })
            if (e.target.value === 'Nigeria') {
                stateData();
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        setStateList(stateList => [...stateList, state.state])
                });
            }
        }

        if(e.target.name === 'State') {
            setLgaList([])
            StateData.map(state => {
                if(state.state === e.target.value) {
                    state.lgas.map(lga => {
                        setLgaList(lgaList => [...lgaList, lga])
                    })
                }
            })
        }

        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }
        if (formData.FirstName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }
        if (formData.Surname.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }
        if (formData.PhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }
        if (formData.EmailAddress === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }
        if (formData.Gender === "") {
            showAlert("EMPTY FIELD", "Please enter gender", "error");
            return false;
        }
        if (formData.DateOfBirth === "") {
            showAlert("EMPTY FIELD", "Please enter date of birth", "error");
            return false;
        }
        if (formData.Password.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter password", "error");
            return false;
        }


        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password)
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/hr/staff/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Agent Added Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            StaffID: "",
                            Title: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            DateOfBirth: "",
                            MaritalStatus: "",
                            Nationality: "",
                            State: "",
                            Lga: "",
                            Religion: "",
                            PhoneNumber: "",
                            EmailAddress: "",
                            StaffType: "",
                            Designation: "",
                            DepartmentCode: "",
                            IsActive: "0",
                            IsAcademicStaff: "0",
                            IsAdmin: "0",
                            ContactAddress: "",
                            Research: "#",
                            Facebook: "#",
                            Linkedin: "#",
                            Twitter: "#",
                            Scholar: "#",
                            Researchgate: "#",
                            Academia: "#",
                            Orcid: "#",
                            Image: "",
                            Biography: "",
                            Password: "123456789",
                        });
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("STAFF EXIST", "Staff already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/hr/staff/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Staff Updated Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            StaffID: "",
                            Title: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            DateOfBirth: "",
                            MaritalStatus: "",
                            Nationality: "",
                            State: "",
                            Lga: "",
                            Religion: "",
                            PhoneNumber: "",
                            EmailAddress: "",
                            StaffType: "",
                            Designation: "",
                            DepartmentCode: "",
                            IsActive: "0",
                            IsAcademicStaff: "0",
                            IsAdmin: "0",
                            ContactAddress: "",
                            Research: "#",
                            Facebook: "#",
                            Linkedin: "#",
                            Twitter: "#",
                            Scholar: "#",
                            Researchgate: "#",
                            Academia: "#",
                            Orcid: "#",
                            Image: "",
                            Biography: "",
                            Password: "123456789",
                        });
                    } else if (result.data.message === "exist") {
                        showAlert("STAFF EXIST", "Staff already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Agent*/}
                                </div>
                                <h2 className="page-title">
                                    Manage Staff
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               StaffID: "",
                                               Title: "",
                                               FirstName: "",
                                               MiddleName: "",
                                               Surname: "",
                                               Gender: "",
                                               DateOfBirth: "",
                                               MaritalStatus: "",
                                               Nationality: "",
                                               State: "",
                                               Lga: "",
                                               Religion: "",
                                               PhoneNumber: "",
                                               EmailAddress: "",
                                               StaffType: "",
                                               Designation: "",
                                               DepartmentCode: "",
                                               IsActive: "0",
                                               IsAcademicStaff: "0",
                                               IsAdmin: "0",
                                               ContactAddress: "",
                                               Research: "#",
                                               Facebook: "#",
                                               Linkedin: "#",
                                               Twitter: "#",
                                               Scholar: "#",
                                               Researchgate: "#",
                                               Academia: "#",
                                               Orcid: "#",
                                               Image: "",
                                               Biography: "",
                                               Password: "123456789",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Staff
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Staff Report</h3>
                                                {/*<div className="table-responsive">*/}
                                                    {/*<ReportTable data={data} columns={columns} />*/}
                                                <DataTable header={header} body={showTable()} title="Staff Report" />
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageStaffForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    CountryData={CountryData}
                    stateList={stateList}
                    lgaList={lgaList}
                    departmentList={departmentList}
                    designationList={designationList}
                    staffTypeList={staffTypeList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageStaff);
