import React from "react";
import {GATEWAYRRRPAYMENTURL, MERCHANTID} from "../../../../resources/url";
import {currencyConverter} from "../../../../resources/constants";

export default function RemitaPaymentForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Remita Payment</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        {
                            props.formData.RRR === '' ?
                                <div className="row">

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Name of service/purpose </label>
                                        <input
                                            type="text"
                                            name="DESCRIPTION"
                                            className="form-control"
                                            id="DESCRIPTION"
                                            value={props.formData.DESCRIPTION}
                                            onChange={props.onEdit}
                                            placeholder="Name of service/purpose"
                                        />
                                    </div>
                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Amount To Pay (₦)</label>
                                        <input
                                            type="number"
                                            name="AMOUNT"
                                            className="form-control"
                                            id="AMOUNT"
                                            value={props.formData.AMOUNT}
                                            onChange={props.onEdit}
                                            placeholder="Amount To Pay (₦)"
                                        />
                                    </div>

                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Payer Name</label>
                                        <input
                                            name="NAME"
                                            className="form-control"
                                            id="NAME"
                                            value={props.formData.NAME}
                                            onChange={props.onEdit}
                                            placeholder="Payer Name"
                                        />
                                    </div>

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Payer Phone Number</label>
                                        <input
                                            type="number"
                                            name="TELEPHONE"
                                            className="form-control"
                                            id="TELEPHONE"
                                            value={props.formData.TELEPHONE}
                                            onChange={props.onEdit}
                                            max={11}
                                            placeholder="Payer Phone Number"
                                        />

                                    </div>
                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Payer Email Address</label>
                                        <input
                                            type="email"
                                            name="EMAIL"
                                            className="form-control"
                                            id="EMAIL"
                                            value={props.formData.EMAIL}
                                            onChange={props.onEdit}
                                            placeholder="Payer Email Address"
                                        />

                                    </div>

                                </div> :
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <tr>
                                                <th>RRR</th>
                                                <td>{props.formData.RRR}</td>
                                            </tr>
                                            <tr>
                                                <th>Name of service/purpose</th>
                                                <td>{props.formData.DESCRIPTION}</td>
                                            </tr>
                                            <tr>
                                                <th>Amount</th>
                                                <td>{currencyConverter(props.formData.AMOUNT)}</td>
                                            </tr>
                                            <tr>
                                                <th>Payer Name</th>
                                                <td>{props.formData.NAME}</td>
                                            </tr>
                                            <tr>
                                                <th>Payer Email Address</th>
                                                <td>{props.formData.EMAIL}</td>
                                            </tr>
                                            <tr>
                                                <th>Payer Phone Number</th>
                                                <td>{props.formData.TELEPHONE}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                        }


                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                props.formData.RRR === '' ?
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                                    :
                                    <form action={GATEWAYRRRPAYMENTURL} method="POST">
                                        <input id="merchantId" name="merchantId" value={MERCHANTID} type="hidden"/>
                                        <input id="rrr" name="rrr" value={props.formData.RRR} type="hidden"/>
                                        <input id="responseurl" name="responseurl" value={window.location.href} type="hidden"/>
                                        <input id="hash" name="hash" value={props.formData.new_hash} type="hidden"/>
                                        <button type="submit" className="btn btn-primary  ms-auto" id="pay">PAY NOW</button>
                                    </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}