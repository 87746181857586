import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import StudentSelectOption from "../../common/dynamic-select-option/student-select-option";
import ListTile from "./list-tile";

function CourseRegistration(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [amountPerCourse, setAmountPerCourse] = useState(0)
    const [courseAmount, setCourseAmount] = useState(0)
    const [creditUnitRegistered, setCreditUnitRegistered] = useState(0)
    const [courseList, setCourseList] = useState([])
    const [settingsList, setSettingsList] = useState([])
    const [courseID, setCourseID] = useState([])
    const [courseCode, setCourseCode] = useState([])
    const [courseCourseRegStatus, setCourseCourseRegStatus] = useState("initial")
    const header = ["S/N", "APPID", "Name", "Email", "Phone", "Gender", "Programme", "Admission_Year", "Action"];
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        Semester: "",
        Session: "",
        Level: "",
        CourseID: "",
        CourseCode: "",
        StudentName: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        StudentSemester: "",
        StudentDepartment: "",
        StudentLevel: "",
        StudentProgramme: "",
        EmailAddress: "",
        PhoneNumber: "",
        CourseAmount: 0,
        GENERATE_PAYMENT: "0",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            APPID: "",
            STUDENTID: "",
            Semester: "",
            Level: "",
            CourseID: "",
            CourseCode: "",
            StudentName: "",
            Session: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            StudentSemester: "",
            StudentDepartment: "",
            StudentLevel: "",
            StudentProgramme: "",
            EmailAddress: "",
            CourseAmount: 0,
            GENERATE_PAYMENT: '0',
            PhoneNumber: "",
        });
    }

    const getAllData = async () => {
        await axios.get(`${serverLink}staff/registration/academic/settings/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSettingsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);


    useEffect( () => {
        if (formData.STUDENTID !== ""){
            getData();
        }

    }, [formData.STUDENTID]);

    useEffect( () => {
        if (formData.Level.toString()  !== "" && formData.Semester.toString() !== ""){
            getStudentCourseData(formData.Level, formData.Semester)
        }

    }, [formData.Level.toString()]);

    useEffect( () => {
        if (formData.Level.toString() !== "" && formData.Semester.toString() !== ""){
            getStudentCourseData(formData.Level, formData.Semester)
        }

    }, [formData.Semester.toString()]);

    const checkAllCourses = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');

        for (let i = 0; i < checkBox.length ; i++) {
            if (!checkBox[i].checked) {
                checkBox[i].checked = true;
                let creditUnit = checkBox[i].value;
                let selectedCourse = checkBox[i].getAttribute("data_code");
                let selectedCourseID = checkBox[i].getAttribute("data_id");
                setCreditUnitRegistered(prevState => prevState + parseInt(creditUnit))
                setCourseAmount(amountPerCourse * (i+1))
                setCourseCode(prevState => [...prevState, selectedCourse])
                setCourseID(prevState => [...prevState, selectedCourseID])
            }else{
                checkBox[i].checked = false;
                let creditUnit = checkBox[i].value;
                let selectedCourse = checkBox[i].getAttribute("data_code");
                let selectedCourseID = checkBox[i].getAttribute("data_id");
                setCreditUnitRegistered(prevState => prevState - parseInt(creditUnit))
                setCourseAmount(prevState => prevState - amountPerCourse)
                setCourseCode([])
                setCourseID([])
            }
        }

    }

    const getData = async () => {
        setCourseCourseRegStatus('initial')
        setCourseCode([])
        setCourseID([])
        setCourseAmount(0)
        await axios.get(`${serverLink}staff/registration/student/details/${btoa(formData.STUDENTID)}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let data = result.data[0];
                    setFormData({
                        ...formData,
                        APPID: data.APPID,
                        STUDENTID: data.STUDENTID,
                        StudentName: data.FIRST_NAME+" "+data.MIDDLE_NAME+" "+data.LAST_NAME,
                        FirstName: data.FIRST_NAME,
                        MiddleName: data.MIDDLE_NAME,
                        LastName: data.LAST_NAME,
                        StudentLevel: data.STUDENT_LEVEL,
                        StudentDepartment: data.DEPART_ID,
                        StudentProgramme: data.PROGRAMME,
                        EmailAddress: data.EMAIL,
                        PhoneNumber: data.TELEPHONE,
                        Semester: "",
                        Level: "",
                        GENERATE_PAYMENT: '0',
                    })
                    document.getElementById('student-details').style.display = "block";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getStudentCourseData = async (level, semester) => {
        await axios.get(`${serverLink}staff/registration/student/courses/${btoa(formData.STUDENTID)}/${formData.StudentDepartment}/${level}/${semester}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let data = result.data;
                    let amountPayable = 0;

                    setCourseCourseRegStatus("pending")
                    setCourseList(data);
                    if (parseInt(formData.StudentLevel) <= 2){
                        amountPayable = 3000;
                    }else {
                        amountPayable = 5000;
                    }
                    setAmountPerCourse(amountPayable)
                }else{
                    setCourseCourseRegStatus("registered")
                    setCourseList([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onCheck = (e) => {
        let creditUnit = e.target.value;
        let selectedCourse = e.target.getAttribute("data_code");
        let selectedCourseID = e.target.getAttribute("data_id");
        let code = "";
        let id = "";

        if (e.target.checked){
            setCourseAmount(prevState => prevState + amountPerCourse)
            setCreditUnitRegistered(prevState => prevState + parseInt(creditUnit))
            courseList.filter(item => item.COURSE_CODE.toString() === selectedCourse.toString()).map((e,i)=> {
                code = e.COURSE_CODE;
                id = e.COURSE_ID;
            })
            setCourseCode(prevState => [...prevState, code])
            setCourseID(prevState => [...prevState, id])
        }else {
            setCourseAmount(prevState => prevState - amountPerCourse)
            setCreditUnitRegistered(prevState => prevState - parseInt(creditUnit))
            let newCourseCode = courseCode.filter(e => e.toString() !== selectedCourse.toString())
            let newCourseID = courseID.filter(e => e.toString() !== selectedCourseID.toString())
            setCourseCode(newCourseCode)
            setCourseID(newCourseID)
        }
    }

    const onEdit = (e) => {
        if (e.target.id === "GENERATE_PAYMENT") {
            if (e.target.checked) {
                setFormData({...formData, GENERATE_PAYMENT: "1",});
            } else {
                setFormData({...formData, GENERATE_PAYMENT: "0",});
            }
        }else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    }

    const onSubmit = async () => {
        if (formData.STUDENTID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select student", "error");
            return false;
        }
        if (formData.Session.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select session to pay for", "error");
            return false;
        }
        if (formData.Level.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select level", "error");
            return false;
        }
        if (formData.Semester.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select semester", "error");
            return false;
        }

        let sendData = {
            ...formData,
            CourseCode: courseCode,
            CourseID: courseID,
            CourseAmount: courseAmount,
        }

        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/registration/course/registration`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Course(s) Successfully Registered");
                    setIsFormLoading(false);
                    setCourseCourseRegStatus('initial')
                    setCourseCode([])
                    setCourseID([])
                    setCourseAmount(0)
                    setCreditUnitRegistered(0)
                    setFormData({
                        ...formData,
                        Semester: "",
                        Session: "",
                        Level: "",
                        GENERATE_PAYMENT: '0',
                        CourseID: "",
                        CourseCode: "",
                        CourseAmount: 0,
                    })
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong registering the course(s). Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Course Registration
                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <StudentSelectOption formData={formData} setFormData={setFormData} />
                                                        </div>

                                                        <div id="student-details" style={{display: 'none'}}>
                                                            <div className="hr-text hr-text-left"><h5>Student Details</h5></div>
                                                            <ListTile caption="Student Name" name={formData.StudentName}/>
                                                            <ListTile caption="Student ID" name={formData.STUDENTID}/>
                                                            <ListTile caption="Programme" name={formData.StudentProgramme}/>
                                                            <ListTile caption="Current Level" name={formData.StudentLevel+"00 Level"}/>
                                                            <ListTile caption="Phone Number" name={formData.PhoneNumber}/>
                                                            <ListTile caption="Email Address" name={formData.EmailAddress}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-9" id="registration-content">
                                                        {
                                                            formData.STUDENTID === "" ?
                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="icon alert-icon" width="24"
                                                                                 height="24" viewBox="0 0 24 24"
                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                 fill="none" strokeLinecap="round"
                                                                                 strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"/>
                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                <polyline points="11 12 12 12 12 16 13 16"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div> Please select a student to register course(s)  </div>
                                                                    </div>
                                                                    <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Course Registration Details</h5></div>
                                                                    <div className="form-group mb-3 col-md-12">
                                                                        <select
                                                                            className="form-control form-control-solid"
                                                                            name="Session"
                                                                            id="Session"
                                                                            value={formData.Session}
                                                                            onChange={onEdit}
                                                                        >
                                                                            <option value="">Select session to pay for</option>
                                                                            {
                                                                                settingsList.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                                                                                    return  (<option className={item.Status.toString() === "1" ? "text-success" : ""} key={index} value={item.Session}>{item.Session} => {item.Year} {item.Status.toString() === "1" ? " (Active Session)" : ""}</option>)
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <select
                                                                            className="form-select"
                                                                            name="Level"
                                                                            id="Level"
                                                                            value={formData.Level}
                                                                            onChange={onEdit}
                                                                        >
                                                                            <option value="">Select Level</option>
                                                                            <option value="1">100 Level</option>
                                                                            <option value="2">200 Level</option>
                                                                            <option value="3">300 Level</option>
                                                                            <option value="4">400 Level</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <select
                                                                            className="form-select"
                                                                            name="Semester"
                                                                            id="Semester"
                                                                            value={formData.Semester}
                                                                            onChange={onEdit}
                                                                        >
                                                                            <option value="">Select Semester</option>
                                                                            <option value="1">First Semester</option>
                                                                            <option value="2">Second Semester</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        {
                                                                            courseCourseRegStatus === "initial" ?
                                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                                    <div className="d-flex">
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                 className="icon alert-icon" width="24"
                                                                                                 height="24" viewBox="0 0 24 24"
                                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                                 fill="none" strokeLinecap="round"
                                                                                                 strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                                      fill="none"/>
                                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                                <polyline points="11 12 12 12 12 16 13 16"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div> Please select the course level and semester to proceed.  </div>
                                                                                    </div>
                                                                                    <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                                </div>
                                                                                :
                                                                                courseCourseRegStatus === "registered" ?
                                                                                    <div className="alert alert-important alert-danger alert-dismissible" role="alert">
                                                                                        <div className="d-flex">
                                                                                            <div>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                     className="icon alert-icon" width="24"
                                                                                                     height="24" viewBox="0 0 24 24"
                                                                                                     strokeWidth="2" stroke="currentColor"
                                                                                                     fill="none" strokeLinecap="round"
                                                                                                     strokeLinejoin="round">
                                                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                                                          fill="none"/>
                                                                                                    <circle cx="12" cy="12" r="9"/>
                                                                                                    <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                                    <polyline points="11 12 12 12 12 16 13 16"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div> This student has registered all courses for the selected level and semester.  </div>
                                                                                        </div>
                                                                                        <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        <div className="col-md-12 table-responsive-md">
                                                                                            <table className="table table-bordered">
                                                                                                <thead>
                                                                                                <tr>
                                                                                                    <th><input type="checkbox"
                                                                                                               id="checkAll"
                                                                                                               className="checkAll"
                                                                                                               onChange={checkAllCourses}
                                                                                                    /> CHECK ALL</th>
                                                                                                    <th>S/N</th>
                                                                                                    <th>CODE</th>
                                                                                                    <th>TITLE</th>
                                                                                                    <th>UNIT</th>
                                                                                                </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                {
                                                                                                    courseList.length > 0 ?
                                                                                                        courseList.map((item, index) => {
                                                                                                            return (
                                                                                                                <tr key={index}>
                                                                                                                    <td><input type="checkbox"
                                                                                                                               id="checkItem"
                                                                                                                               data_code={item.COURSE_CODE}
                                                                                                                               data_id={item.COURSE_ID}
                                                                                                                               className="checkItem"
                                                                                                                               name="checkItem"
                                                                                                                               value={item.CREDIT_UNIT}
                                                                                                                               onChange={onCheck} />
                                                                                                                    </td>
                                                                                                                    <td>{index + 1}</td>
                                                                                                                    <td>{item.COURSE_CODE}</td>
                                                                                                                    <td>{item.COURSE_TITLE}</td>
                                                                                                                    <td>{item.CREDIT_UNIT}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        }) : (<>
                                                                                                            <tr>
                                                                                                                <td colSpan={5}><span
                                                                                                                    className="alert alert-info">No available course!</span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>)
                                                                                                }

                                                                                                <tr>
                                                                                                    <td colSpan={4}
                                                                                                        className="text-center text-primary"  style={{ fontSize: '20px', fontWeight: 'bold'}}>TOTAL CREDIT
                                                                                                        UNIT(S) SELECTED &nbsp; &nbsp; &nbsp;
                                                                                                        <span style={{color: '#000000', fontSize: '20px', fontWeight: 'bold'}}>{creditUnitRegistered}</span>
                                                                                                    </td>
                                                                                                    <td colSpan={5}>
                                                                                                        <div
                                                                                                            className="bg-dark text-white text-center"
                                                                                                            style={{borderRadius: '50px'}}>{courseList.map(e=>e.CREDIT_UNIT).reduce((a, b) =>  parseInt(a) + parseInt(b), 0)}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan={5} className="text-center text-primary"  style={{ fontSize: '20px', fontWeight: 'bold'}}> <b>TOTAL AMOUNT PAYABLE</b> &nbsp; &nbsp; &nbsp;
                                                                                                        <span style={{color: '#000000', fontSize: '20px', fontWeight: 'bold'}}><del>N</del><b>{courseAmount}</b></span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                            <div className="hr-text hr-text-left"><h2>Payment Section</h2></div>
                                                                                            <div className="list-group-item mb-3">
                                                                                                <div className="row align-items-center">
                                                                                                    <div className="col-auto"><input type="checkbox" id="GENERATE_PAYMENT"  name="GENERATE_PAYMENT" onChange={onEdit} className="form-check-input"/></div>
                                                                                                    <div className="col text-truncate">
                                                                                                        <span className="text-reset d-block">Generate Payment Receipt ?</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="alert alert-important  alert-dismissible" role="alert" style={{backgroundColor: '#cccccc', color: 'black'}}>
                                                                                                <div className="d-flex">
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon" width="24"
                                                                                                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                                                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                            <path d="M12 9v2m0 4v.01"/>
                                                                                                            <path
                                                                                                                d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Are you sure you want to register the above course(s) to <b>{formData.StudentName}</b> ?
                                                                                                    </div>
                                                                                                </div>
                                                                                                <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                                            </div>

                                                                                            <div
                                                                                                className="text-center mb-5 col-md-4 offset-sm-4">
                                                                                                <button
                                                                                                    className="btn btn-primary form-control"
                                                                                                    id="btn_register_courses"
                                                                                                    type="button"
                                                                                                    onClick={onSubmit}
                                                                                                >Register
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                        }

                                                                    </div>
                                                                </>
                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<NewStudentEnrolmentForm*/}
                {/*    formData={formData}*/}
                {/*    onEdit={onEdit}*/}
                {/*    onSubmit={onSubmit}*/}
                {/*    IsFormLoading={IsFormLoading}*/}
                {/*    setFormData={setFormData}*/}
                {/*/>*/}
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(CourseRegistration);
