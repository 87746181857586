import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";

function ReferralCandidates(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [referralList, setReferralList] = useState([])
    const header = ["S/N", "ReferralCode", "Referral Name", "Phone Number", "Referral Type", "Applicant ID", "Applicant Name",  "Account Number",  "Bank Name", "Status", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        ReferralName: "",
        PhoneNumber: "",
        ReferralType: "",
        ReferralCode: "",
        AccountNumber: "",
        BankName: "",
        Status: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/referral/candidates`)
            .then((result) => {
                if (result.data.message === "success") {
                    setReferralList(result.data.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return referralList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralCode}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralName}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralType}</td>
                        <td className="text-xs font-weight-bold">{item.APPID}</td>
                        <td className="text-xs font-weight-bold">{item.StudentName}</td>
                        <td className="text-xs font-weight-bold">{item.AccountNumber}</td>
                        <td className="text-xs font-weight-bold">{item.BankName}</td>
                        <td className={`text-xs font-weight-bold ${item.IsPaid?.toString() ==="1" ? "text-success" : "text-danger"}`}>{item.IsPaid?.toString() ==="1" ? "Paid" : "Not Paid"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "}
                               onClick={() => {
                                   mark_paid(item.EntryID);
                               }}>
                                Mark As Paid
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }


    const mark_paid = async (id) => {
        toast.info("please wait...");
        await axios.patch(`${serverLink}staff/registration/referral/mark-paid/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Record Marked As Paid Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Referral Candidates
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Referral Candidate(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Referral Candidates(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ReferralCandidates);
