import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {setgeneralDetails} from "../../../actions/actions";
import {Link} from "react-router-dom";

function DefermentReport(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [studentList, setStudentList] = useState([])
    const header = ["S/N", "StudentID", "Student Name", "Programme", "Session Applied", "Return Session", "Semester Applied", "Return Semester", "Reason", "Status", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        StudentID: "",
        StudentName: "",
        Programme: "",
        Reason: "",
        CurrentSession: "",
        ReturnSession: "",
        CurrentSemester: 0,
        ReturnSemester: 0,
        Status: 0,
        ActionType: 0,
        NumberOfSemesters: 0,
        StatusBy: `${props.loginData[0]?.StaffID}`,
        IsFormLoading2: IsFormLoading2,
        courses: [],
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            EntryID: "",
            StudentID: "",
            StudentName: "",
            Programme: "",
            Reason: "",
            CurrentSession: "",
            ReturnSession: "",
            CurrentSemester: 0,
            ReturnSemester: 0,
            Status: 0,
            ActionType: 0,
            NumberOfSemesters: 0,
            GENERATE_PAYMENT: "0",
        });
    }

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/deferment/request/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let request = result.data;
                    setStudentList(request);
                }else {
                    setStudentList([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                let color = item.Status === 0 ? 'text-primary' : item.Status === 1 ?  'text-success': item.Status === 2 ?  'text-success': item.Status === 3 ?  'text-danger' : 'text-primary';
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index + 1}</td>
                        <td className="text-xs font-weight-bold">{item.StudentID}</td>
                        <td className="text-xs font-weight-bold">{item.StudentName}</td>
                        <td className="text-xs font-weight-bold">{item.Programme}</td>
                        <td className="text-xs font-weight-bold">{item.CurrentSession}</td>
                        <td className="text-xs font-weight-bold">{item.ReturnSession}</td>
                        <td className="text-xs font-weight-bold">{item.CurrentSemester.toString() === "1" ? 'First' : 'Second'}</td>
                        <td className="text-xs font-weight-bold">{item.ReturnSemester.toString() === "1" ? 'First' : 'Second'}</td>
                        <td className="text-xs font-weight-bold">{item.Reason}</td>
                        <td className={`text-xs font-weight-bold ${color}`}>{item.Status === 0 ? 'Applied' : item.Status === 1 ? 'Director Approved' : item.Status === 2 ? 'Registrar Approved' : item.Status === 3 ? 'Rejected' : 'Returned'}</td>
                        <td className="text-xs font-weight-bold">
                            {
                                item.Status === 3 ?
                                    <>--</>
                                    :
                                    <Link to="/registration/deferment/Acknowledgement" className={`btn btn-primary btn-sm`}
                                          style={{color: 'white'}}
                                          onClick={() => {
                                              props.setOnGeneralDetails(item)
                                          }}>
                                        Detail
                                    </Link>

                            }
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Deferment Report
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <h3 className="card-title">Deferment Report</h3>
                                        <DataTable header={header} body={showTable()} title="Deferment Report" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefermentReport);
