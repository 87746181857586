import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import StudentSelectOption from "../../common/dynamic-select-option/student-select-option";
import ListTile from "../../common/list-tile/list-tile";
import ApplicantSelectOption from "../../common/dynamic-select-option/applicant-select-option";
import DataTable from "../../common/data-table/data-table";
import {currencyConverter, formatDateAndTime} from "../../../resources/constants";
import {set} from "immutable";

function PostPayment(props) {
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [paymentHistoryList, setPaymentHistoryList] = useState([])
    const [paymentTypeList, setPaymentTypeList] = useState([])
    const [cart, setCart] = useState([])
    const header = ["S/N", "Payment Title", "Code", "Amount", "Add to Cart"];
    const header2 = ["S/N","Payment Type","Amount", "Transaction ID", "Transaction Date", "Print"];
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        Cart: "",
        StudentName: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        StudentSemester: "",
        StudentDepartment: "",
        StudentLevel: "",
        StudentProgramme: "",
        EmailAddress: "",
        StudentType: "",
        GENERATE_PAYMENT: '0',
        PhoneNumber: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            APPID: "",
            STUDENTID: "",
            Cart: "",
            StudentName: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            StudentSemester: "",
            StudentDepartment: "",
            StudentLevel: "",
            StudentProgramme: "",
            EmailAddress: "",
            StudentType: "",
            GENERATE_PAYMENT: '0',
            PhoneNumber: "",
        });
    }

    useEffect( () => {
        if (formData.APPID !== "" ){
            getApplicantData();
        }

    }, [formData.APPID]);

    useEffect( () => {
        if (formData.STUDENTID !== "" ){
            getStudentData();
        }

    }, [formData.STUDENTID]);



    const getStudentData = async () => {
        setCart([])
        unCheckAll()
        await axios.get(`${serverLink}staff/finance/student-payment-data/${btoa(formData.STUDENTID)}`)
            .then((result) => {
                if (result.data.StudentData.length > 0) {
                    let data = result.data.StudentData[0];
                    let paymentTypes = result.data.Payments;
                    let paymentHistory = result.data.PaymentHistory;
                    setPaymentTypeList(paymentTypes)
                    setPaymentHistoryList(paymentHistory)
                    setFormData({
                        ...formData,
                        APPID: data.APPID,
                        STUDENTID: data.STUDENTID,
                        StudentName: data.FIRST_NAME+" "+data.MIDDLE_NAME+" "+data.LAST_NAME,
                        FirstName: data.FIRST_NAME,
                        MiddleName: data.MIDDLE_NAME,
                        LastName: data.LAST_NAME,
                        StudentLevel: data.STUDENT_LEVEL,
                        StudentDepartment: data.DEPART_ID,
                        StudentProgramme: data.PROGRAMME,
                        EmailAddress: data.EMAIL,
                        PhoneNumber: data.TELEPHONE,
                        Semester: "",
                        Level: "",
                        GENERATE_PAYMENT: '0',
                    })
                    document.getElementById('student-details').style.display = "block";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getApplicantData = async () => {
        setCart([])
        unCheckAll()
        await axios.get(`${serverLink}staff/finance/applicant-payment-data/${formData.APPID}`)
            .then((result) => {
                if (result.data.StudentData.length > 0) {
                    let data = result.data.StudentData[0];
                    let paymentTypes = result.data.Payments;
                    let paymentHistory = result.data.PaymentHistory;
                    setPaymentTypeList(paymentTypes)
                    setPaymentHistoryList(paymentHistory)
                    setFormData({
                        ...formData,
                        APPID: data.APPID,
                        STUDENTID: data.STUDENTID,
                        StudentName: data.FIRST_NAME+" "+data.MIDDLE_NAME+" "+data.LAST_NAME,
                        FirstName: data.FIRST_NAME,
                        MiddleName: data.MIDDLE_NAME,
                        LastName: data.LAST_NAME,
                        StudentLevel: data.STUDENT_LEVEL,
                        StudentDepartment: data.DEPART_ID,
                        StudentProgramme: data.PROGRAMME,
                        EmailAddress: data.EMAIL,
                        PhoneNumber: data.TELEPHONE,
                        Semester: "",
                        Level: "",
                        GENERATE_PAYMENT: '0',
                    })
                    document.getElementById('student-details').style.display = "block";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onCheck = (e) => {
        let paymentData = e.target.getAttribute("data");
        let paymentCode = e.target.getAttribute("data_code");

        if (e.target.checked){
            setCart(prevState => [...prevState, JSON.parse(paymentData)])
        }else {
            let newCart = cart.filter(e=> e.PAYMENT_CODE !== paymentCode)
            setCart(newCart)
        }
    }

    const onEdit = (e) => {
        if (e.target.id === "StudentType"){
            setFormData({
                ...formData,
                APPID: "",
                STUDENTID: "",
            })
            if (e.target.value !== ""){
                document.getElementById('student-select').style.display = "block";
                document.getElementById('student-details').style.display = "none";
            }else{
                document.getElementById('student-details').style.display = "none";
                document.getElementById('student-select').style.display = "none";
            }

        }

            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });

    }

    const onSubmit = async () => {
        console.log(formData)
        if (formData.StudentType === "Student"){
            if (formData.STUDENTID.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select student", "error");
                return false;
            }
        }else{
            if (formData.APPID.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select student", "error");
                return false;
            }
        }


        if (cart.length < 1) {
            showAlert("EMPTY FIELD", "Please select at least one item", "error");
            return false;
        }

        let sendData = {
            ...formData,
            Cart: cart,
        }

        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/finance/post-payment`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Payment Posted Successful");
                    setIsFormLoading(false);
                    setCart([])
                    unCheckAll()
                    if (formData.StudentType === "Student"){
                        getStudentData()
                    }else {
                        getApplicantData()
                    }
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong posting the payment. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const unCheckAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');
        for (let i = 0; i < checkBox.length ; i++) {
            if (checkBox[i].checked) {
                checkBox[i].checked = false;
            }
        }
    }


    const  showTable = () => {
        try {
            return paymentTypeList.length > 0 && paymentTypeList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PAYMENT_TYPE}</td>
                        <td className="text-xs font-weight-bold">{item.PAYMENT_CODE}</td>
                        <td className="text-xs font-weight-bold">{currencyConverter(item.PAYMENT_FEE)}</td>
                        <td className="text-xs font-weight-bold">
                            <input type="checkbox"
                                   id="checkItem"
                                   data_code={item.PAYMENT_CODE}
                                   data_id={item.PAYMENT_ID}
                                   data={JSON.stringify(item)}
                                   className="checkItem"
                                   name="checkItem"
                                   value={item.PAYMENT_FEE}
                                   onChange={onCheck} />
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable2= () => {
        try {
            return paymentHistoryList.length > 0 &&  paymentHistoryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PAYMENT_TYPE}</td>
                        <td className="text-xs font-weight-bold">{currencyConverter(item.AMOUNT)}</td>
                        <td className="text-xs font-weight-bold">{item.TRANSACTION_ID}</td>
                        <td className="text-xs font-weight-bold">{item.DATE_PAID === "" ? "" : formatDateAndTime(item.DATE_PAID, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <button type="button" className={"btn btn-primary btn-sm  text-center"}
                                            onClick={() => {
                                                // let sendData = {
                                                //     ...formData2,
                                                //     EntryID: item.EntryID,
                                                //     StaffID: item.StaffID,
                                                //     GroupID: item.GroupID,
                                                // }
                                                // onSubmit2(sendData);
                                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg>
                                    </button>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Post Payment
                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <select
                                                                className="form-select form-control"
                                                                name="StudentType"
                                                                id="StudentType"
                                                                value={formData.StudentType}
                                                                onChange={onEdit}
                                                            >
                                                                <option value="">Select Student Type</option>
                                                                <option value="Applicant">Applicant</option>
                                                                <option value="Student">Student</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group mb-3" id="student-select"  style={{display: 'none'}}>
                                                            {
                                                                formData.StudentType === "Applicant" ?
                                                                    <ApplicantSelectOption formData={formData} setFormData={setFormData} />
                                                                    :
                                                                    <StudentSelectOption formData={formData} setFormData={setFormData} />
                                                            }
                                                        </div>

                                                        <div id="student-details" style={{display: 'none'}}>
                                                            <div className="hr-text hr-text-left"><h5>Student Details</h5></div>
                                                            <ListTile caption="Student Name" name={formData.StudentName}/>
                                                            <ListTile caption="Student ID" name={formData.STUDENTID}/>
                                                            <ListTile caption="Programme" name={formData.StudentProgramme}/>
                                                            <ListTile caption="Current Level" name={formData.StudentLevel+"00 Level"}/>
                                                            <ListTile caption="Phone Number" name={formData.PhoneNumber}/>
                                                            <ListTile caption="Email Address" name={formData.EmailAddress}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-9" id="registration-content">
                                                        {
                                                            formData.APPID === "" ?
                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="icon alert-icon" width="24"
                                                                                 height="24" viewBox="0 0 24 24"
                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                 fill="none" strokeLinecap="round"
                                                                                 strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"/>
                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                <polyline
                                                                                    points="11 12 12 12 12 16 13 16"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div> Please select student to post payment
                                                                        </div>
                                                                    </div>
                                                                    <a className="btn-close btn-close-white"
                                                                       data-bs-dismiss="alert" aria-label="close"/>
                                                                </div>
                                                                :
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <ul className="nav nav-tabs card-header-tabs nav-fill " data-bs-toggle="tabs" role="tablist">
                                                                            <li className="nav-item"
                                                                                role="presentation">
                                                                                <a href="#tabs-menu"
                                                                                   className="nav-link active"
                                                                                   data-bs-toggle="tab"
                                                                                   aria-selected="true"
                                                                                   role="tab">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className="icon icon-tabler icon-tabler-tournament"
                                                                                        width="24" height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        <path stroke="none"
                                                                                              d="M0 0h24v24H0z"
                                                                                              fill="none"></path>
                                                                                        <circle cx="4" cy="4"
                                                                                                r="2"></circle>
                                                                                        <circle cx="20" cy="10"
                                                                                                r="2"></circle>
                                                                                        <circle cx="4" cy="12"
                                                                                                r="2"></circle>
                                                                                        <circle cx="4" cy="20"
                                                                                                r="2"></circle>
                                                                                        <path
                                                                                            d="M6 12h3a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-3"></path>
                                                                                        <path
                                                                                            d="M6 4h7a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-2"></path>
                                                                                        <path d="M14 10h4"></path>
                                                                                    </svg>
                                                                                    Payment Types </a>
                                                                            </li>
                                                                            <li className="nav-item"
                                                                                role="presentation">
                                                                                <a href="#tabs-sub-menu"
                                                                                   className="nav-link"
                                                                                   data-bs-toggle="tab"
                                                                                   aria-selected="false"
                                                                                   role="tab"
                                                                                   tabIndex="-1">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="9" y1="7" x2="10" y2="7" /><line x1="9" y1="13" x2="15" y2="13" /><line x1="13" y1="17" x2="15" y2="17" /></svg>
                                                                                    Payment History</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="tab-content">
                                                                            <div className="tab-pane active show" id="tabs-menu" role="tabpanel">
                                                                                <div className="page-header d-print-none">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col">
                                                                                            <div className="page-pretitle">
                                                                                            </div>
                                                                                            <h2>Payment Types</h2>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <DataTable tableID="group"  header={header} body={showTable()} title="Group List"/>
                                                                                {
                                                                                    cart.length > 0 &&
                                                                                   <>
                                                                                       <hr/>
                                                                                       <h2>Cart</h2>
                                                                                       <div className="row">
                                                                                           <div className="col-md-12">
                                                                                               <table className="table  table-bordered">
                                                                                                   <thead>
                                                                                                   <tr>
                                                                                                       <th>S/N</th>
                                                                                                       <th>Items</th>
                                                                                                       <th>Amount</th>
                                                                                                   </tr>
                                                                                                   </thead>
                                                                                                   <tbody>
                                                                                                   {
                                                                                                       cart.length > 0 && cart.map((item, key) => {
                                                                                                           return(
                                                                                                               <tr key={key}>
                                                                                                                   <td>{key+1}</td>
                                                                                                                   <td>{item.PAYMENT_TYPE}</td>
                                                                                                                   <td>{currencyConverter(item.PAYMENT_FEE)}</td>
                                                                                                               </tr>
                                                                                                           )
                                                                                                       })
                                                                                                   }
                                                                                                   </tbody>
                                                                                               </table>
                                                                                               <table className="table table-bordered">
                                                                                                   <thead>
                                                                                                   <tr>
                                                                                                       <th className="text-center">
                                                                                                           <h3>Total amount: </h3>
                                                                                                       </th>
                                                                                                       <td width={240}><h3><span>{currencyConverter(cart.map(e=>parseFloat(e.PAYMENT_FEE)).reduce((a,b) => a+b, 0))}</span></h3></td>
                                                                                                   </tr>
                                                                                                   </thead>
                                                                                               </table>
                                                                                           </div>
                                                                                           <div
                                                                                               className="text-center mb-5 col-md-4 offset-sm-4">
                                                                                               <button
                                                                                                   className="btn btn-primary form-control"
                                                                                                   id="btn_register_courses"
                                                                                                   type="button"
                                                                                                   onClick={onSubmit}
                                                                                               >POST PAYMENT
                                                                                               </button>
                                                                                           </div>
                                                                                       </div>
                                                                                   </>
                                                                                }
                                                                            </div>
                                                                            <div className="tab-pane" id="tabs-sub-menu" role="tabpanel">
                                                                                  <DataTable  tableID="payment-history" header={header2} body={showTable2()} title="Payment History"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<NewStudentEnrolmentForm*/}
                {/*    formData={formData}*/}
                {/*    onEdit={onEdit}*/}
                {/*    onSubmit={onSubmit}*/}
                {/*    IsFormLoading={IsFormLoading}*/}
                {/*    setFormData={setFormData}*/}
                {/*/>*/}
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(PostPayment);
