import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {APIKEY, CHECKSTATUSURL, GATEWAYURL, MERCHANTID, serverLink, SERVICETYPEID} from "../../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../../common/dataLoader/dataLoader";
import DataTable from "../../../common/data-table/data-table";
import {useLocation, useNavigate} from "react-router";
import RemitaPaymentForm from "./remita-payment-form";
import {currencyConverter, formatDateAndTime} from "../../../../resources/constants";
const CryptoJS = require("crypto-js");

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function RemitaPayment(props) {
    const navigate = useNavigate();
    let query = useQuery();
    let queryData = query.get("orderID");

    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [paymentList, setPaymentList] = useState([])
    const header = ["S/N", "Payment ID", "service/purpose Of Payment", "Amount", "Payer Name", "Email", "Phone", "Payment Date"];
    const [formData, setFormData] = useState({
        NAME: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
        TELEPHONE: `${props.loginData[0]?.PhoneNumber}`,
        EMAIL: `${props.loginData[0]?.EmailAddress}`,
        AMOUNT:  0,
        DESCRIPTION:  '',
        RRR: "",
        new_hash: "",
        TransID: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, []);

    useEffect(() => {
        if (queryData){
            checkPaymentStatus();
        }
    }, [!queryData ? null : queryData])


    const getData = async () => {
        await axios.get(`${serverLink}staff/finance/remita/payment/list`)
            .then((result) => {
                if (result.data.length) {
                    setPaymentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const makeRemitaPayment = () => {
        if (formData.DESCRIPTION === ''){
            toast.error('Please enter purpose of payment');
            return false;
        }
        if (formData.AMOUNT === 0){
            toast.error('Please enter amount');
            return false;
        }

        if (formData.NAME === ''){
            toast.error('Please enter payer name');
            return false;
        }

        if (formData.TELEPHONE === ''){
            toast.error('Please enter payer phone number');
            return false;
        }

        if (formData.EMAIL === ''){
            toast.error('Please enter payer email address');
            return false;
        }


        let merchantId = MERCHANTID;
        let apiKey = APIKEY;
        let serviceTypeId = SERVICETYPEID;
        let d = new Date();
        let orderId = d.getTime();
        let totalAmount = parseFloat(formData.AMOUNT);
        let apiHash = CryptoJS.SHA512(merchantId + serviceTypeId + orderId + totalAmount + apiKey);
        let req = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
            },
            body: JSON.stringify({
                "serviceTypeId": serviceTypeId,
                "amount": totalAmount,
                "orderId": orderId,
                "payerName": formData?.NAME,
                "payerEmail": formData?.EMAIL,
                "payerPhone": formData?.TELEPHONE,
                "description": formData.DESCRIPTION,
            }),
        };
        toast.info("Please wait...");
        fetch(`${GATEWAYURL}`, req)
            .then((response) => response.json())
            .then(async (responseJson) => {
                if (responseJson.statuscode.toString() === "025") {
                    let RRR = responseJson.RRR;
                    let new_hash_string = MERCHANTID + RRR + APIKEY;
                    let new_hash = CryptoJS.SHA512(new_hash_string);
                    let sendData = {
                        ...formData,
                        RRR: RRR,
                        orderId: orderId
                    }
                    setFormData({...formData, RRR: RRR, new_hash: new_hash})
                    await axios.post(`${serverLink}staff/finance/remita/invoice`, sendData)
                        .then(result => {
                            const response = result.data.message;
                            if (response === 'error') {
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            } else {
                                toast.success("Payment invoice generated");
                            }
                            setIsLoading(false)
                        })
                        .catch(error => {
                            console.log(error)
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your network connection and try again!",
                                "error"
                            );
                            setIsLoading(false)
                        })
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const checkPaymentStatus = async () => {
        try {
            const rrr = query.get("RRR");
            const orderID = query.get("orderID");
            const apiHash = CryptoJS.SHA512(orderID + APIKEY + MERCHANTID);

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                }
            };

            const response = await axios.get(`${CHECKSTATUSURL}/${MERCHANTID}/${orderID}/${apiHash}/orderstatus.reg`, config);

            if (response.data.status.toString() === "00") {
                const sendData = {
                    RRR: response.data.rrr,
                    ORDER_ID: response.data.orderId,
                };

                const result = await axios.patch(`${serverLink}staff/finance/remita/payment`, sendData);
                const { message } = result.data;

                if (message === 'success') {
                    toast.success("Payment successful. Please wait while the system is fetching payment record...");
                    getData();
                }else if (message === 'error') {
                    showAlert(
                        "ERROR",
                        "Your payment was received successfully. But something went wrong updating your record.",
                        "error"
                    );
                } else {
                    showAlert(
                        "ERROR",
                        "Your payment was received successfully. But something went wrong updating your record.",
                        "error"
                    );
                }

                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your network connection and try again!",
                "error"
            );
        }
    };


    const  showTable = () => {
        try {
            return paymentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PaymentID}</td>
                        <td className="text-xs font-weight-bold">{item.ServiceName}</td>
                        <td className="text-xs font-weight-bold">{currencyConverter(item.Amount)}</td>
                        <td className="text-xs font-weight-bold">{item.PayerName}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.PaymentDate, 'date')}</td>
                    </tr>
                );
            });        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Remita Payment
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               AMOUNT:  0,
                                               DESCRIPTION:  0,
                                               RRR: "",
                                               new_hash: "",
                                               TransID: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Make Payment
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Payments</h3>
                                                <DataTable header={header} body={showTable()} title="Remita Payments" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RemitaPaymentForm
                    formData={formData}
                    setFormData={setFormData}
                    onEdit={onEdit}
                    onSubmit={makeRemitaPayment}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(RemitaPayment);
