import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {decryptData, projectURL, serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import {currencyConverter, formatDateAndTime, isValidUrl} from "../../../resources/constants";
import {Link, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import DataTable from "../../common/data-table/data-table";

function StudentDashboard(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { slug } = useParams();

    if (slug === "") navigate("/");

    const [IsLoading, setIsLoading] = useState(true)
    const [showTBL, setShowTBL] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [CourseList, setCourseList] = useState([])
    const [PaymentList, setPaymentList] = useState([])
    const [courses, setCourse] = useState([])
    const [level, setLevel] = useState([])
    const [semester, setSemester] = useState([])
    const header = ["S/N", "Course Code", "Course Title","Level",  "Semester", "Date Registered"];
    const header2 = ["S/N","Payment Type","Amount", "Transaction ID", "Transaction Date", "Print"];
    const [formData, setFormData] = useState({
        PersonalInfo: {
            APPID: "",
            STUDENTID: "",
            EMAIL: "",
            FIRST_NAME: "",
            MIDDLE_NAME: "",
            LAST_NAME: "",
            DOB: "",
            GENDER: "",
            BLOOD_GROUP: "",
            TELEPHONE: "",
            PROG_ID: "",
            ADMISSION_BATCH: "",
            APP_STATUS: "",
            ACCEPT_STATUS: "",
            CLEARANCE_STATUS: "",
            IS_STUDENT: "",
            DEPART_ID: "",
            STUDENT_LEVEL: "",
            PROG_CHANGE: "",
            SHORTLIST: "",
            IS_ACTIVE: "",
            STUDENT_STATUS: "",
            ADMISSION_YEAR: "",
            PROGRAMME: "",
            DEPARTMENT: "",
            PICTURE: "",
            DATE: "",
        },
        ContactInfo: {
            SN: "",
            APPID: "",
            STUDENTID: "",
            NATIONALITY: "",
            STATE: "",
            LGA: "",
            ADDRESS: "",
            DATE: "",
        },
        EduBackground: {
            APPID: "",
            STUDENTID: "",
            Sittings: "",
            Exam_Number: "",
            Exam_Type: "",
            Exam_Year: "",
            Subject1: "",
            Subject2: "",
            Subject3: "",
            Subject4: "",
            Subject5: "",
            Subject6: "",
            Subject7: "",
            Subject8: "",
            Subject9: "",
            DATE: "",
        },
        NOK: {
            APPID: "",
            STUDENTID: "",
            FIRST_NAME: "",
            LAST_NAME: "",
            PHONE: "",
            EMAIL: "",
            ADDRESS: "",
        },
        Qualifications: {
            APPID: "",
            STUDENTID: "",
            INSTITUTION: "",
            PROGRAMME: "",
            QUALIFICATION: "",
            CLASS_OF_DEGREE: "",
            CGPA: "",
            YEAR: "",
        },
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
            getData(formData.APPID)
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/report/student/dashboard/data/${decryptData(slug)}`)
            .then((result) => {
                if (result.data.Biodata.length > 0) {
                    let Biodata = result.data?.Biodata[0];
                    let Contact = result.data?.Contact[0];
                    let Courses = result.data?.Courses;
                    let NOK = result?.data.NOK[0];
                    let Olevel = result?.data.Olevel[0];
                    let Qualification = result.data?.Qualification[0];
                    let Payments = result.data?.Payments;
                    let course_data = result.data?.CourseData;


                    setStudentList(Biodata);
                    setCourseList(Courses);
                    setPaymentList(Payments);
                    setCourse(course_data)
                    setLevel([...new Set(Courses.map(e=>e.LEVEL))])
                    setSemester([...new Set(Courses.map(e=>e.SEMESTER))])
                    setFormData({
                        ...formData,
                        PersonalInfo: {
                            APPID: Biodata?.APPID,
                            STUDENTID: Biodata?.STUDENTID,
                            EMAIL: Biodata?.EMAIL,
                            FIRST_NAME: Biodata?.FIRST_NAME,
                            MIDDLE_NAME: Biodata?.MIDDLE_NAME,
                            LAST_NAME: Biodata?.LAST_NAME,
                            DOB: Biodata?.DOB,
                            GENDER: Biodata?.GENDER,
                            BLOOD_GROUP: Biodata?.BLOOD_GROUP,
                            TELEPHONE: Biodata?.TELEPHONE,
                            PROG_ID: Biodata?.PROG_ID,
                            ADMISSION_BATCH: Biodata?.ADMISSION_BATCH,
                            APP_STATUS: Biodata?.APP_STATUS,
                            ACCEPT_STATUS: Biodata?.ACCEPT_STATUS,
                            CLEARANCE_STATUS: Biodata?.CLEARANCE_STATUS,
                            IS_STUDENT: Biodata?.IS_STUDENT,
                            DEPART_ID: Biodata?.DEPART_ID,
                            STUDENT_LEVEL: Biodata?.STUDENT_LEVEL,
                            PROG_CHANGE: Biodata?.PROG_CHANGE,
                            SHORTLIST: Biodata?.SHORTLIST,
                            IS_ACTIVE: Biodata?.IS_ACTIVE,
                            STUDENT_STATUS: Biodata?.STUDENT_STATUS,
                            ADMISSION_YEAR: Biodata?.ADMISSION_YEAR,
                            PICTURE: Biodata?.PICTURE,
                            PROGRAMME: Biodata?.PROGRAMME,
                            DEPARTMENT: Biodata?.DEPARTMENT,
                            DATE: Biodata?.DATE,
                        },
                        ContactInfo: {
                            SN: Contact?.SN,
                            APPID: Contact?.APPID,
                            STUDENTID: Contact?.STUDENTID,
                            NATIONALITY: Contact?.NATIONALITY,
                            STATE: Contact?.STATE,
                            LGA: Contact?.LGA,
                            ADDRESS: Contact?.ADDRESS,
                            DATE: Contact?.DATE,
                        },
                        EduBackground: {
                            APPID: Olevel?.APPID,
                            STUDENTID: Olevel?.STUDENTID,
                            Sittings: Olevel?.Sittings,
                            Exam_Number: Olevel?.Exam_Number,
                            Exam_Type: Olevel?.Exam_Type,
                            Exam_Year: Olevel?.Exam_Year,
                            Subject1: Olevel?.Subject1,
                            Subject2: Olevel?.Subject2,
                            Subject3: Olevel?.Subject3,
                            Subject4: Olevel?.Subject4,
                            Subject5: Olevel?.Subject5,
                            Subject6: Olevel?.Subject6,
                            Subject7: Olevel?.Subject7,
                            Subject8: Olevel?.Subject8,
                            Subject9: Olevel?.Subject9,
                            DATE: Olevel?.DATE,
                        },
                        NOK: {
                            APPID: NOK?.APPID,
                            STUDENTID: NOK?.STUDENTID,
                            FIRST_NAME: NOK?.FIRST_NAME,
                            LAST_NAME: NOK?.LAST_NAME,
                            PHONE: NOK?.PHONE,
                            EMAIL: NOK?.EMAIL,
                            ADDRESS: NOK?.ADDRESS,
                        },
                        Qualifications: {
                            APPID: Qualification?.APPID,
                            STUDENTID: Qualification?.STUDENTID,
                            INSTITUTION: Qualification?.INSTITUTION,
                            PROGRAMME: Qualification?.PROGRAMME,
                            QUALIFICATION: Qualification?.QUALIFICATION,
                            CLASS_OF_DEGREE: Qualification?.CLASS_OF_DEGREE,
                            CGPA: Qualification?.CGPA,
                            YEAR: Qualification?.YEAR,
                        },
                    })
                }
                setIsLoading(false)
                setShowTBL(true)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return CourseList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.COURSE_CODE}</td>
                        <td className="text-xs font-weight-bold">{courses.filter(e=>e.COURSE_ID.toString() === item.COURSEID.toString())[0].COURSE_TITLE}</td>
                        <td className="text-xs font-weight-bold">{item.LEVEL}00</td>
                        <td className="text-xs font-weight-bold">{item.SEMESTER.toString() === "1" ? "First" : "Second"}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.DATE_REG, "date")}</td>
                        {/*<td className="text-xs font-weight-bold">*/}
                        {/*    <Link to="/" className={"btn btn-danger btn-sm "}*/}
                        {/*          onClick={() => {*/}

                        {/*          }}>*/}
                        {/*        DROP*/}
                        {/*    </Link></td>*/}
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable2= () => {
        try {
            return PaymentList.length > 0 &&  PaymentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PAYMENT_TYPE}</td>
                        <td className="text-xs font-weight-bold">{currencyConverter(item.AMOUNT)}</td>
                        <td className="text-xs font-weight-bold">{item.TRANSACTION_ID}</td>
                        <td className="text-xs font-weight-bold">{item.DATE_PAID === "" ? "" : formatDateAndTime(item.DATE_PAID, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <button type="button" className={"btn btn-primary btn-sm  text-center"}
                                    onClick={() => {
                                        // let sendData = {
                                        //     ...formData2,
                                        //     EntryID: item.EntryID,
                                        //     StaffID: item.StaffID,
                                        //     GroupID: item.GroupID,
                                        // }
                                        // onSubmit2(sendData);
                                    }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg>
                            </button>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Student Dashboard
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <ul className="nav nav-tabs nav-fill" data-bs-toggle="tabs">
                                                <li className="nav-item">
                                                    <a href="#Personal-Info" className="nav-link active"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="7" r="4" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                                                        Personal Info</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#Contact" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                                                        Contact Info</a>
                                                </li>

                                                <li className="nav-item">
                                                    <a href="#NOK" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="7" r="4" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
                                                        Next of Kin</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#O-Level" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5" /><circle cx="6" cy="14" r="3" /><path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5" /></svg>
                                                        O'Level</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#A-level" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school"
                                                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"/>
                                                            <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"/>
                                                        </svg>                                                        A'Level</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#payment-history" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 18v-10.948a1.05 1.05 0 0 1 1.968 -.51l6.064 10.916a1.05 1.05 0 0 0 1.968 -.51v-10.948" /><path d="M5 10h14" /><path d="M5 14h14" /></svg>
                                                        Payments</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#course" className="nav-link"
                                                       data-bs-toggle="tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" /><path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" /><line x1="3" y1="6" x2="3" y2="19" /><line x1="12" y1="6" x2="12" y2="19" /><line x1="21" y1="6" x2="21" y2="19" /></svg>
                                                        Courses</a>
                                                </li>
                                            </ul>
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className="tab-pane active show" id="Personal-Info">
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row g-2 align-items-center">
                                                                        <div className="col-auto">
                                                                            <span className="avatar avatar-lg"  style={{backgroundImage: `url(${projectURL}assets/passport/${formData.PersonalInfo.PICTURE})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '150px' , height: '200px' }}/>
                                                                        </div>
                                                                        <div className="col" style={{marginTop: '-80px'}}>
                                                                            <h3 className="card-title m-0" style={{fontSize: '20px'}}>
                                                                                <a href="#"> {formData.PersonalInfo.FIRST_NAME} {formData.PersonalInfo.MIDDLE_NAME} {formData.PersonalInfo.LAST_NAME}</a>
                                                                            </h3>
                                                                            <div className="text-muted">
                                                                                {!formData.PersonalInfo.STUDENTID ? formData.PersonalInfo.APPID : formData.PersonalInfo.STUDENTID}
                                                                            </div>
                                                                            <div className="small mt-1">
                                                                                <span className="badge bg-green"/> Active
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <a className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report">Edit Profile</a>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">

                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Gender
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {formData.PersonalInfo.GENDER}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Date Of Birth
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {formatDateAndTime(formData.PersonalInfo.DOB, 'date')}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Blood-Group
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {!formData.PersonalInfo.BLOOD_GROUP ? "N/A" : formData.PersonalInfo.BLOOD_GROUP}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">

                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Phone Number
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {formData.PersonalInfo.TELEPHONE}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row mt-3">

                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Email Address
                                                                                            </div>
                                                                                            <div className="text-muted text-lowercase">
                                                                                                {formData.PersonalInfo.EMAIL}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Level
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {!formData.PersonalInfo.STUDENT_LEVEL ? "N/A" : formData.PersonalInfo.STUDENT_LEVEL+"00"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                PROGRAMME
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {formData.PersonalInfo.PROGRAMME}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                                            <div className="card card-sm">
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-auto">
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="fw-bold">
                                                                                                Admission Year
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {formData.PersonalInfo.ADMISSION_YEAR}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="Contact">
                                                        <div className="table-responsive-md">
                                                            <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">Contact Address</h4></div>
                                                            {!formData.ContactInfo.STATE ?
                                                                <div className="alert alert-warning alert-important text-center">
                                                                    No record found!
                                                                </div>
                                                                :
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <td className="fw-bold">Nationality</td>
                                                                        <td>{formData.ContactInfo.NATIONALITY}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">State</td>
                                                                        <td>{formData.ContactInfo.STATE}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">LGA</td>
                                                                        <td>{formData.ContactInfo.LGA}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Address</td>
                                                                        <td>{formData.ContactInfo.ADDRESS}</td>
                                                                    </tr>

                                                                    </thead>
                                                                </table>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="NOK">
                                                        <div className="table-responsive-md">
                                                            <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">Next Of Kin Details</h4></div>
                                                            {!formData.NOK.FIRST_NAME ?
                                                                <div className="alert alert-warning alert-important text-center">
                                                                    No record found!
                                                                </div>
                                                                :
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <td className="fw-bold">First Name</td>
                                                                        <td>{formData.NOK.FIRST_NAME}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Last Name</td>
                                                                        <td>{formData.NOK.LAST_NAME}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Phone Number</td>
                                                                        <td>{formData.NOK.PHONE}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Email Address</td>
                                                                        <td>{formData.NOK.EMAIL}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Address</td>
                                                                        <td>{formData.NOK.ADDRESS}</td>
                                                                    </tr>

                                                                    </thead>
                                                                </table>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="O-Level">
                                                        <div className="table-responsive-md">
                                                            <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">O'Level Details</h4></div>
                                                            {!formData.EduBackground.Exam_Number ?
                                                                <div className="alert alert-warning alert-important text-center">
                                                                    No record found!
                                                                </div>
                                                                :
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <td className="fw-bold">Exam Number</td>
                                                                        <td>{formData.EduBackground.Exam_Number}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="fw-bold">Exam Year</td>
                                                                        <td>{formData.EduBackground.Exam_Year}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="fw-bold">Exam Type</td>
                                                                        <td>{formData.EduBackground.Exam_Type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Number Of Sittings</td>
                                                                        <td>{formData.EduBackground.Sittings}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Subject 1</td>
                                                                        <td>{formData.EduBackground.Subject1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Subject 2</td>
                                                                        <td>{formData.EduBackground.Subject2}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Subject 3</td>
                                                                        <td>{formData.EduBackground.Subject3}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Subject 4</td>
                                                                        <td>{formData.EduBackground.Subject4}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Subject 5</td>
                                                                        <td>{formData.EduBackground.Subject5}</td>
                                                                    </tr>
                                                                    {
                                                                        !formData.EduBackground.Subject6 ?
                                                                            <></>
                                                                            :
                                                                            <>
                                                                                <tr>
                                                                                    <td  className="fw-bold">Subject 6</td>
                                                                                    <td>{formData.EduBackground.Subject6}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td  className="fw-bold">Subject 7</td>
                                                                                    <td>{formData.EduBackground.Subject7}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td  className="fw-bold">Subject 8</td>
                                                                                    <td>{formData.EduBackground.Subject8}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td  className="fw-bold">Subject 9</td>
                                                                                    <td>{formData.EduBackground.Subject9}</td>
                                                                                </tr>
                                                                            </>
                                                                    }

                                                                    </thead>
                                                                </table>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="A-level">
                                                        <div className="table-responsive-md">
                                                            <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">A'Level Details</h4></div>
                                                            {!formData.Qualifications.INSTITUTION ?
                                                                <div className="alert alert-warning alert-important text-center">
                                                                    No record found!
                                                                </div>
                                                                :
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <td className="fw-bold">Institution Name</td>
                                                                        <td>{formData.Qualifications.INSTITUTION}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Programme</td>
                                                                        <td>{formData.Qualifications.PROGRAMME}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Qualification</td>
                                                                        <td>{formData.Qualifications.QUALIFICATION}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Class Of Degree</td>
                                                                        <td>{formData.Qualifications.CLASS_OF_DEGREE}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">CGPA</td>
                                                                        <td>{formData.Qualifications.CGPA}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  className="fw-bold">Year Obtained</td>
                                                                        <td>{formData.Qualifications.YEAR}</td>
                                                                    </tr>

                                                                    </thead>
                                                                </table>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="payment-history">
                                                        <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">Payment History</h4></div>
                                                        <DataTable  tableID="payment-history-data" header={header2} body={showTable2()} title="Payment History"/>
                                                    </div>
                                                    <div className="tab-pane" id="course">
                                                        <div className="hr-text hr-text-left mt-3 fw-bold"><h4 className="fw-bold">Registered Courses</h4></div>
                                                        <DataTable  tableID="Registered-Courses" header={header} body={showTable()} title="Registered Courses"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(StudentDashboard);
