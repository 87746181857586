import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import ConversionStudentsForm from "./conversion-students-form";

function AddConversionStudent(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [programme, setProgramme] = useState([])
    const header = ["S/N", "APPID", "Name", "Email", "Phone", "Gender", "Programme", "Admission_Year", "Old RegNo."];
    const [formData, setFormData] = useState({
        APPID: "",
        FIRST_NAME: "",
        MIDDLE_NAME: "",
        LAST_NAME: "",
        TELEPHONE: "",
        EMAIL: "",
        PROG_ID: "",
        OLD_REGNO: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/conversion/list`)
            .then((result) => {
                if (result.data.message === "success") {
                    setStudentList(result.data.data);
                    setProgramme(result.data.programme);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.APPID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.EMAIL}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.ADMISSION_YEAR}</td>
                        <td className="text-xs font-weight-bold">{item.OLD_REGNO}</td>
                    </tr>
                );
            });        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.FIRST_NAME.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }

        if (formData.LAST_NAME.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter last name", "error");
            return false;
        }

        if (formData.TELEPHONE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Phone Number", "error");
            return false;
        }

        if (formData.EMAIL.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter email", "error");
            return false;
        }

        if (formData.PROG_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select programme", "error");
            return false;
        }


        if (formData.APPID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/registration/conversion/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Record Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            APPID: "",
                            FIRST_NAME: "",
                            MIDDLE_NAME: "",
                            LAST_NAME: "",
                            TELEPHONE: "",
                            EMAIL: "",
                            PROG_ID: "",
                            OLD_REGNO: "",
                        });

                    }else if(result.data.message === "exist"){
                        toast.error("Record Already Exist");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/registration/conversion/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Referral Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            APPID: "",
                            FIRST_NAME: "",
                            MIDDLE_NAME: "",
                            LAST_NAME: "",
                            TELEPHONE: "",
                            EMAIL: "",
                            PROG_ID: "",
                            OLD_REGNO: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Conversion Student
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               APPID: "",
                                               FIRST_NAME: "",
                                               MIDDLE_NAME: "",
                                               LAST_NAME: "",
                                               TELEPHONE: "",
                                               EMAIL: "",
                                               PROG_ID: "",
                                               OLD_REGNO: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Conversion Student
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Conversion Student(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Conversion Student(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConversionStudentsForm
                    formData={formData}
                    setFormData={setFormData}
                    programme={programme}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddConversionStudent);
