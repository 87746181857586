import React from "react";

export default function NewStudentEnrolmentForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Enrolment Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="hr-text hr-text-left"><h2>Student Details</h2></div>
                            <ul className="ml-3" style={{marginLeft: '10px'}}>
                                <li className="list-check">Application ID:  <b>{props.formData.APPID}</b></li>
                                <li>Student Name: <b>{props.formData.STUDENT_NAME}</b> </li>
                                <li>Email Address:  <b>{props.formData.EMAIL}</b></li>
                                <li>Phone Number:  <b>{props.formData.TELEPHONE}</b></li>
                                <li>Programme:  <b>{props.formData.PROGRAMME}</b></li>
                                <li>Admission Year:  <b>{props.formData.ADMISSION_YEAR}</b></li>
                            </ul>
                            <div className="list-group-item mb-3">
                                <div className="row align-items-center">
                                    <div className="col-auto"><input type="checkbox" id="GENERATE_PAYMENT"  name="GENERATE_PAYMENT" onChange={props.onEdit} className="form-check-input"/></div>
                                    <div className="col text-truncate">
                                        <span className="text-reset d-block">Generate tuition Payment/Receipt</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label className='form-label' >Enrolment Session</label>
                                    <select
                                        className="form-control form-control-solid"
                                        name="AdmissionSession"
                                        id="AdmissionSession"
                                        value={props.formData.AdmissionSession}
                                        onChange={props.onEdit}
                                    >
                                        <option value="">Select Session</option>
                                        {
                                            props.settingsList.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                                                return  (<option key={index} value={item.Session}>{item.Session} {item.Status.toString() === "1" ? " (Active Session)" : ""}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <label className='form-label' >Entry Level</label>
                                    <select
                                        className="form-control form-control-solid"
                                        name="StudentLevel"
                                        id="StudentLevel"
                                        value={props.formData.StudentLevel}
                                        onChange={props.onEdit}
                                    >
                                        <option value="">Select Entry Level</option>
                                        <option value="1">100</option>
                                        <option value="2">200</option>
                                        <option value="3">300</option>
                                        <option value="4">400</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <label className='form-label' >Mode Of Entry</label>
                                    <select
                                        className="form-control form-control-solid"
                                        name="ModeOfEntry"
                                        id="ModeOfEntry"
                                        value={props.formData.ModeOfEntry}
                                        onChange={props.onEdit}
                                    >
                                        <option value="">Select Mode Of Entry</option>
                                        <option value="Application">Application</option>
                                        <option value="Transfer">Conversion</option>
                                    </select>
                                </div>
                            </div>
                            <div className="alert alert-important alert-warning alert-dismissible" role="alert">
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon" width="24"
                                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M12 9v2m0 4v.01"/>
                                            <path
                                                d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Are you sure you want to enrol the above student?
                                    </div>
                                </div>
                                <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Enroll
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}