import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import DropOfCourseRequestForm from "./drop-of-course-request-form";

function DropOfCourseRequest(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [studentList, setStudentList] = useState([])
    const header = ["S/N", "StudentID", "Student Name", "Programme", "Session Applied", "Return Session", "Semester Applied", "Return Semester", "Reason", "Status", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        StudentID: "",
        StudentName: "",
        Programme: "",
        Reason: "",
        CurrentSession: "",
        ReturnSession: "",
        CurrentSemester: 0,
        ReturnSemester: 0,
        Status: 0,
        ActionType: 0,
        NumberOfSemesters: 0,
        StatusBy: `${props.loginData[0]?.StaffID}`,
        RegistrarApproval: "",
        IsFormLoading2: IsFormLoading2,
        courses: [],
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            EntryID: "",
            StudentID: "",
            StudentName: "",
            Programme: "",
            Reason: "",
            CurrentSession: "",
            ReturnSession: "",
            CurrentSemester: 0,
            ReturnSemester: 0,
            Status: 0,
            ActionType: 0,
            NumberOfSemesters: 0,
            GENERATE_PAYMENT: "0",
            IsFormLoading2: IsFormLoading2,
            courses: [],
        });
    }

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/drop-of-course/request/list`)
            .then((result) => {
                if (result.data.message === 'success') {
                    let request = result.data.request;
                    setStudentList(request);
                }else {
                    setStudentList([]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                let color = item.Status === 0 ? 'text-primary' : item.Status === 1 ?  'text-success': item.Status === 2 ?  'text-success': item.Status === 3 ?  'text-danger' : 'text-primary';
                let disabled =  item.Status === 1 ?  'disabled': item.Status === 2 ? 'disabled': item.Status === 3 ? 'disabled' : '';
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StudentID}</td>
                        <td className="text-xs font-weight-bold">{item.StudentName}</td>
                        <td className="text-xs font-weight-bold">{item.Programme}</td>
                        <td className="text-xs font-weight-bold">{item.CurrentSession}</td>
                        <td className="text-xs font-weight-bold">{item.ReturnSession}</td>
                        <td className="text-xs font-weight-bold">{item.CurrentSemester === 1 ? 'First' : 'Second'}</td>
                        <td className="text-xs font-weight-bold">{item.ReturnSemester === 1 ? 'First' : 'Second'}</td>
                        <td className="text-xs font-weight-bold">{item.Reason}</td>
                        <td className={`text-xs font-weight-bold ${color}`}>{item.Status === 0 ? 'Applied' : item.Status === 1 ?  'Director Approved': item.Status === 2 ?  'Registrar Approved': item.Status === 3 ?  'Rejected' : 'Returned'}</td>
                        <td className="text-xs font-weight-bold d-flex">
                            <a href="#" className={`btn btn-primary btn-sm ${disabled}`} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={async () => {
                                   setIsFormLoading2(true)
                                   const courses = await axios.get(`${serverLink}staff/registration/drop-of-course/request/courses/${item.EntryID}`).finally(()=>{setIsFormLoading2(false)})
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       StudentID: item.StudentID,
                                       StudentName: item.StudentName,
                                       Programme: item.Programme,
                                       Reason: item.Reason,
                                       CurrentSession: item.CurrentSession,
                                       ReturnSession: item.ReturnSession,
                                       CurrentSemester: item.CurrentSemester === 1 ? 'First' : 'Second',
                                       ReturnSemester: item.ReturnSemester === 1 ? 'First' : 'Second',
                                       Status: item.Status === 0 ? 'Applied' : item.Status === 1 ?  'Director Approved': item.Status === 2 ?  'Registrar Approved': item.Status === 3 ?  'Rejected' : 'Returned',
                                       ActionType: 1,
                                       NumberOfSemesters: item.NumberOfSemesters,
                                       IsFormLoading2: IsFormLoading2,
                                       courses: courses.data,
                                   });
                               }}>
                                Approve
                            </a>
                            <span> &nbsp; </span>
                            <a href="#" className={`btn btn-danger btn-sm ${disabled}`} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={async () => {
                                   setIsFormLoading2(true)
                                   const courses = await axios.get(`${serverLink}staff/registration/drop-of-course/request/courses/${item.EntryID}`).finally(()=>{setIsFormLoading2(false)})
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       StudentID: item.StudentID,
                                       StudentName: item.StudentName,
                                       Programme: item.Programme,
                                       Reason: item.Reason,
                                       CurrentSession: item.CurrentSession,
                                       ReturnSession: item.ReturnSession,
                                       CurrentSemester: item.CurrentSemester === 1 ? 'First' : 'Second',
                                       ReturnSemester: item.ReturnSemester === 1 ? 'First' : 'Second',
                                       Status: item.Status === 0 ? 'Applied' : item.Status === 1 ?  'Director Approved': item.Status === 2 ?  'Registrar Approved': item.Status === 3 ?  'Rejected' : 'Returned',
                                       ActionType: 2,
                                       NumberOfSemesters: item.NumberOfSemesters,
                                       IsFormLoading2: IsFormLoading2,
                                       courses: courses.data,
                                   });
                               }}>
                                Reject
                            </a>
                                 <span> &nbsp; </span>
                            <a href="#" className={`btn btn-success btn-sm ${disabled}`} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={async () => {
                                   setIsFormLoading2(true)
                                   const courses = await axios.get(`${serverLink}staff/registration/drop-of-course/request/courses/${item.EntryID}`).finally(()=>{setIsFormLoading2(false)})
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       StudentID: item.StudentID,
                                       StudentName: item.StudentName,
                                       Programme: item.Programme,
                                       Reason: item.Reason,
                                       CurrentSession: item.CurrentSession,
                                       ReturnSession: item.ReturnSession,
                                       CurrentSemester: item.CurrentSemester === 1 ? 'First' : 'Second',
                                       ReturnSemester: item.ReturnSemester === 1 ? 'First' : 'Second',
                                       Status: item.Status === 0 ? 'Applied' : item.Status === 1 ?  'Director Approved': item.Status === 2 ?  'Registrar Approved': item.Status === 3 ?  'Rejected' : 'Returned',
                                       ActionType: 3,
                                       NumberOfSemesters: item.NumberOfSemesters,
                                       IsFormLoading2: IsFormLoading2,
                                       courses: courses.data,
                                   });
                               }}>
                                View
                            </a>

                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        if (e.target.id === "GENERATE_PAYMENT"){
            if(e.target.checked){
                setFormData({ ...formData, [e.target.id]: "1",  });
            }else{
                setFormData({ ...formData, [e.target.id]: "0",  });
            }
        }else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    }

    const onSubmit = async () => {
        let status = 0;
        if (formData.StudentID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "StudentID Not Found", "error");
            return false;
        }

        if (formData.ActionType === 1){
            status = 1;
        }else{
            status = 3;
        }

        let sendData = {
            ...formData,
            Status: status
        }
        setIsFormLoading(true);
        await axios.patch(`${serverLink}staff/registration/drop-of-course/request/director`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    if (formData.ActionType === 1){
                        toast.success("Drop Of Course Approved Successfully");
                    }else{
                        toast.success("Drop Of Course Rejected Successfully");
                    }
                    setIsFormLoading(false);
                    document.getElementById("closeModal").click();
                    resetFormData();
                    getData();
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong approving this student drop of course. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Drop Of Course Request &nbsp; <span style={{fontSize: '10px !important'}}> <small>  (Director)</small> </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <h3 className="card-title">Drop Of Course Request</h3>
                                        <DataTable header={header} body={showTable()} title="Drop Of Course Request" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DropOfCourseRequestForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(DropOfCourseRequest);
