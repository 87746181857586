import React from "react";
import ProgrammeSelectOption from "../../common/dynamic-select-option/progrmme-select-option";

export default function ConversionStudentsForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Conversion Student Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Fist Name</label>
                                <input
                                    name="FIRST_NAME"
                                    className="form-control"
                                    id="FIRST_NAME"
                                    value={props.formData.FIRST_NAME}
                                    onChange={props.onEdit}
                                    placeholder="Fist Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Middle Name</label>
                                <input
                                    name="MIDDLE_NAME"
                                    className="form-control"
                                    id="MIDDLE_NAME"
                                    value={props.formData.MIDDLE_NAME}
                                    onChange={props.onEdit}
                                    placeholder="Middle Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Last Name</label>
                                <input
                                    name="LAST_NAME"
                                    className="form-control"
                                    id="LAST_NAME"
                                    value={props.formData.LAST_NAME}
                                    onChange={props.onEdit}
                                    placeholder="Last Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    name="TELEPHONE"
                                    className="form-control"
                                    id="TELEPHONE"
                                    value={props.formData.TELEPHONE}
                                    onChange={props.onEdit}
                                    max={11}
                                    placeholder="Phone Number"
                                />

                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    name="EMAIL"
                                    className="form-control"
                                    id="EMAIL"
                                    value={props.formData.EMAIL}
                                    onChange={props.onEdit}
                                    max={11}
                                    placeholder="Email Address"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Programme</label>
                                <ProgrammeSelectOption  formData={props.formData} setFormData={props.setFormData} />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Old Registration Number</label>
                                <input
                                    name="OLD_REGNO"
                                    className="form-control"
                                    id="OLD_REGNO"
                                    value={props.formData.OLD_REGNO}
                                    onChange={props.onEdit}
                                    placeholder="Old Registration Number"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}