import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import ManageReferralForm from "./manage-referral-form";

function ManageReferrals(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [referralList, setReferralList] = useState([])
    const header = ["S/N", "Referral Name", "Phone Number", "Referral Type", "Referral Code",  "Account Number",  "Bank Name", "Status", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        ReferralName: "",
        PhoneNumber: "",
        ReferralType: "",
        ReferralCode: "",
        AccountNumber: "",
        BankName: "",
        Status: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/referral/list`)
            .then((result) => {
                if (result.data.message === "success") {
                    setReferralList(result.data.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return referralList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralName}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralType}</td>
                        <td className="text-xs font-weight-bold">{item.ReferralCode}</td>
                        <td className="text-xs font-weight-bold">{item.AccountNumber}</td>
                        <td className="text-xs font-weight-bold">{item.BankName}</td>
                        <td className={`text-xs font-weight-bold ${item.Status?.toString() ==="Active" ? "text-success" : "text-danger"}`}>{item.Status?.toString() ==="Active" ? "Active" : "In-Active"}</td>
                        <td className="text-xs font-weight-bold d-flex">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               style={{marginRight: '5px'}}
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       ReferralName: item.ReferralName,
                                       PhoneNumber: item.PhoneNumber,
                                       ReferralType: item.ReferralType,
                                       ReferralCode: item.ReferralCode,
                                       AccountNumber: item.AccountNumber,
                                       BankName: item.BankName,
                                       Status: item.Status,
                                   });
                               }}>
                                Edit
                            </a>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={() => {
                                   delete_item(item.EntryID);
                               }}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.ReferralName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter referral name", "error");
            return false;
        }

        if (formData.PhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter referral Phone Number", "error");
            return false;
        }

        if (formData.ReferralType.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select referral type", "error");
            return false;
        }

        if (formData.AccountNumber.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter referral account number", "error");
            return false;
        }

        if (formData.BankName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select referral bank name", "error");
            return false;
        }

        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select referral status", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/registration/referral/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Referral Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            ReferralName: "",
                            PhoneNumber: "",
                            ReferralType: "",
                            ReferralCode: "",
                            AccountNumber: "",
                            BankName: "",
                            Status: "",
                        });

                    }else if(result.data.message === "exist"){
                        toast.error("Referral Already Exist");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/registration/referral/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Referral Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            ReferralName: "",
                            PhoneNumber: "",
                            ReferralType: "",
                            ReferralCode: "",
                            AccountNumber: "",
                            BankName: "",
                            Status: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}staff/registration/referral/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Referrals
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               ReferralName: "",
                                               PhoneNumber: "",
                                               ReferralType: "",
                                               ReferralCode: "",
                                               AccountNumber: "",
                                               BankName: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Referral
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Referral(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Referral(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageReferralForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageReferrals);
