import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    generalDetailsReducer,
    loginDetailsReducer,
    permissionDetailsReducer,
    farmer_info_reducer, farmers_data_reducer, agent_data_reducer,
} from "./detailsReducer";

import storage from "redux-persist/lib/storage";
import {projectCode} from "../resources/constants";

const rootReducer = combineReducers({
    generalDetails: generalDetailsReducer,
    permissionData: permissionDetailsReducer,
    loginDetails: loginDetailsReducer,
    farmerInfo: farmer_info_reducer,
    farmersData : farmers_data_reducer,
    agentData : agent_data_reducer,
});

const persistConfig = {
    key: projectCode,
    storage,
};

export default persistReducer(persistConfig, rootReducer);